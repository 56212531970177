// src/pages/Users/Users.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { 
  Typography, 
  Box, 
  CircularProgress, 
  Alert, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  IconButton, 
  Tooltip 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import CreateUser from './CreateUser';
// Удалены импорты ImportUsersModal и FilterModal
import { motion } from 'framer-motion'; // Добавлен импорт motion для анимаций

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  // Удалены состояния для Import и Filter модалов

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/users'); // Убедитесь, что эндпоинт правильный
        setUsers(response.data);
      } catch (error) {
        setError('Ошибка при загрузке пользователей. Попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Вы уверены, что хотите удалить этого пользователя?')) return;

    try {
      await axiosInstance.delete(`/users/${userId}`);
      setUsers((prev) => prev.filter((user) => user.id !== userId));
    } catch (error) {
      alert('Ошибка при удалении пользователя. Попробуйте позже.');
    }
  };

  const handleBlockUser = async (userId) => {
    if (!window.confirm('Вы уверены, что хотите заблокировать этого пользователя?')) return;

    try {
      await axiosInstance.patch(`/users/${userId}/block`); // Эндпоинт для блокировки
      setUsers((prev) =>
        prev.map((user) =>
          user.id === userId ? { ...user, blocked: true } : user
        )
      );
    } catch (error) {
      alert('Ошибка при блокировке пользователя. Попробуйте позже.');
    }
  };

  const handleRedirectToDetail = (userId) => {
    navigate(`/users/${userId}`); // Редирект на страницу деталей пользователя
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Пользователи
      </Typography>

      {/* Обёрнутый в motion.div блок кнопок с анимацией */}
      <Box mb={2} display="flex" justifyContent="flex-end" gap={2}>
        {/* Добавить Пользователя с анимацией */}
        <motion.div
          whileHover={{ scale: 1.05, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.3 }}
        >
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setIsCreateModalOpen(true)}
          >
            Добавить Пользователя
          </Button>
        </motion.div>
        {/* Удалены кнопки "Импортировать Пользователей" и "Фильтр" */}
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Офис</TableCell>
              <TableCell>Команда</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell align="center">Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  Нет пользователей для отображения.
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user.id} hover>
                  <TableCell>
                    <Button 
                      variant="text" 
                      onClick={() => handleRedirectToDetail(user.id)}
                      style={{ textTransform: 'none' }}
                    >
                      {user.id}
                    </Button>
                  </TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.userRole?.name || 'Не указана'}</TableCell>
                  <TableCell>{user.office?.name || 'Не указан'}</TableCell>
                  <TableCell>{user.team?.name || 'Не указана'}</TableCell>
                  <TableCell>{user.blocked ? 'Заблокирован' : 'Активен'}</TableCell>
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title="Удалить">
                        <motion.div
                          whileHover={{ scale: 1.2 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <IconButton 
                            color="error" 
                            onClick={() => handleDeleteUser(user.id)} 
                            aria-label="удалить"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </motion.div>
                      </Tooltip>
                      {!user.blocked && (
                        <Tooltip title="Заблокировать">
                          <motion.div
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <IconButton 
                              color="warning" 
                              onClick={() => handleBlockUser(user.id)} 
                              aria-label="заблокировать"
                            >
                              <BlockIcon />
                            </IconButton>
                          </motion.div>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Модальные окна */}
      {isCreateModalOpen && (
        <CreateUser 
          open={isCreateModalOpen} 
          onClose={() => setIsCreateModalOpen(false)} 
          onUserCreated={(newUser) => setUsers((prev) => [...prev, newUser])} 
        />
      )}
      {/* Удалены ImportUsersModal и FilterModal */}
    </Box>
  );
};

export default Users;
