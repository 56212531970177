// frontend/src/pages/AffiliateLeads/AffiliateLeadsPage.js

import React, { useEffect, useState } from 'react';
import axios from '../../api/axios'; // Импортируем настроенный axios
import './AffiliateLeadsPage.css'; // Импортируем стили

const AffiliateLeadsPage = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAffiliateLeads = async () => {
      try {
        const response = await axios.get('/leads/affiliates/leads'); // Исправленный путь

        // Проверка структуры ответа
        if (Array.isArray(response.data)) {
          setLeads(response.data);
          setFilteredLeads(response.data);

          // Извлечение уникальных статусов для фильтрации
          const uniqueStatuses = [
            ...new Set(
              response.data.map(
                (lead) => (lead.status ? lead.status.name : 'Без статуса')
              )
            ),
          ];
          setStatuses(uniqueStatuses);
        } else {
          throw new Error('Неверный формат данных от сервера.');
        }
      } catch (err) {
        console.error('Ошибка при получении лидов от аффилиатов:', err);
        setError('Не удалось загрузить лиды от аффилиатов.');
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliateLeads();
  }, []);

  useEffect(() => {
    if (selectedStatus === 'all') {
      setFilteredLeads(leads);
    } else {
      setFilteredLeads(
        leads.filter(
          (lead) =>
            lead.status && lead.status.name.toLowerCase() === selectedStatus.toLowerCase()
        )
      );
    }
    setCurrentPage(1); // Сброс страницы при изменении фильтра
  }, [selectedStatus, leads]);

  // Расчёт лидов для текущей страницы
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div>Загрузка лидов от аффилиатов...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="affiliate-leads-page">
      <h1>Лиды от Аффилиатов</h1>

      <div className="filters">
        <label htmlFor="status-filter">Фильтр по статусу:</label>
        <select id="status-filter" value={selectedStatus} onChange={handleStatusChange}>
          <option value="all">Все</option>
          {statuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>

      {filteredLeads.length === 0 ? (
        <div>Нет лидов для отображения.</div>
      ) : (
        <>
          <table className="leads-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Имя</th>
                <th>Фамилия</th>
                <th>Телефон</th>
                <th>Email</th>
                <th>Страна</th>
                <th>Город</th>
                <th>Адрес</th>
                <th>Дата Рождения</th>
                <th>Аффилиат</th>
                <th>Статус</th>
                <th>Назначен</th>
                <th>Создан</th>
                <th>Обновлён</th>
              </tr>
            </thead>
            <tbody>
              {currentLeads.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.id}</td>
                  <td>{lead.first_name}</td>
                  <td>{lead.surname}</td>
                  <td>{lead.phone_number}</td>
                  <td>{lead.email}</td>
                  <td>{lead.country}</td>
                  <td>{lead.city || '-'}</td>
                  <td>{lead.address || '-'}</td>
                  <td>
                    {lead.date_of_birth
                      ? new Date(lead.date_of_birth).toLocaleDateString()
                      : '-'}
                  </td>
                  <td>{lead.affiliate ? lead.affiliate.name : 'N/A'}</td>
                  <td>{lead.status ? lead.status.name : 'N/A'}</td>
                  <td>{lead.assignedTo ? lead.assignedTo.username : 'N/A'}</td>
                  <td>{new Date(lead.created_at).toLocaleString()}</td>
                  <td>{new Date(lead.updated_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Пагинация */}
          <div className="pagination">
            {totalPages > 1 &&
              Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? 'active' : ''}
                >
                  {index + 1}
                </button>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AffiliateLeadsPage;
