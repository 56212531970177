// src/components/AddAffiliateModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import axiosInstance from '../utils/axiosInstance'; // Убедитесь, что путь корректен

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'auto',
};

const AddAffiliateModal = ({ open, handleClose, handleAdd }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    office_id: '',
    daily_lead_limit: '',
    whitelisted_ip: '',
  });

  const [error, setError] = useState(null);
  const [offices, setOffices] = useState([]);
  const [loadingOffices, setLoadingOffices] = useState(false);
  const [officesError, setOfficesError] = useState(null);

  useEffect(() => {
    if (open) {
      fetchOffices();
    }
    // Очистка состояния при закрытии модального окна
    if (!open) {
      setFormData({
        username: '',
        email: '',
        password: '',
        office_id: '',
        daily_lead_limit: '',
        whitelisted_ip: '',
      });
      setError(null);
      setOffices([]);
      setOfficesError(null);
    }
  }, [open]);

  const fetchOffices = async () => {
    try {
      setLoadingOffices(true);
      const response = await axiosInstance.get('/offices'); // Убедитесь, что эндпоинт корректен
      setOffices(response.data || []); // Изменено с response.data.offices на response.data
    } catch (err) {
      console.error('Ошибка при загрузке офисов:', err);
      setOfficesError('Не удалось загрузить список офисов.');
    } finally {
      setLoadingOffices(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { username, email, password, office_id } = formData;

    // Простая валидация
    if (!username || !email || !password || !office_id) {
      setError('Пожалуйста, заполните все обязательные поля');
      return;
    }

    // Дополнительная валидация email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Пожалуйста, введите корректный email');
      return;
    }

    // Преобразование whitelisted_ip из строки в массив
    const affiliateData = {
      username,
      email,
      password,
      office_id: parseInt(office_id, 10),
      daily_lead_limit: parseInt(formData.daily_lead_limit, 10) || null,
      whitelisted_ip: formData.whitelisted_ip
        ? formData.whitelisted_ip.split(',').map(ip => ip.trim())
        : [],
    };

    handleAdd(affiliateData);
    setFormData({
      username: '',
      email: '',
      password: '',
      office_id: '',
      daily_lead_limit: '',
      whitelisted_ip: '',
    });
    setError(null);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-affiliate-title"
    >
      <Box sx={style}>
        <Typography id="add-affiliate-title" variant="h6" component="h2" gutterBottom>
          Добавить аффилиата
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {officesError && <Alert severity="error" sx={{ mb: 2 }}>{officesError}</Alert>}
        <Stack spacing={2}>
          <TextField
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Пароль"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            required
          />
          <FormControl fullWidth required>
            <InputLabel id="office-label">Офис</InputLabel>
            <Select
              labelId="office-label"
              name="office_id"
              value={formData.office_id}
              label="Офис"
              onChange={handleChange}
              disabled={loadingOffices || officesError}
            >
              {loadingOffices ? (
                <MenuItem value="">
                  <CircularProgress size={24} />
                </MenuItem>
              ) : (
                offices.map((office) => (
                  <MenuItem key={office.id} value={office.id}>
                    {office.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <TextField
            label="Лимит лидов в день"
            name="daily_lead_limit"
            type="number"
            value={formData.daily_lead_limit}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Белый список IP (через запятую)"
            name="whitelisted_ip"
            value={formData.whitelisted_ip}
            onChange={handleChange}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Добавить
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddAffiliateModal;
