// frontend/src/pages/Settings/SecuritySettings.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import axiosInstance from '../../utils/axiosConfig'; // Убедитесь, что путь правильный

const SecuritySettings = () => {
  // Состояния для изменения пароля
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Состояния для пользователей и их вайтлиста
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [whitelistAddress, setWhitelistAddress] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  // Состояния для 2FA
  const [twoFAUser, setTwoFAUser] = useState(null);
  const [otp, setOtp] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [twoFADialogOpen, setTwoFADialogOpen] = useState(false);
  const [loading2FA, setLoading2FA] = useState(false);

  // Состояния для уведомлений
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success', 'error', 'warning', 'info'
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/users');
      console.log('Ответ от /users:', response.data); // Для отладки
      // Проверяем, что ответ является массивом
      const usersWithWhitelist = Array.isArray(response.data)
        ? response.data.map(user => ({
            ...user,
            whitelist: user.whitelist || [],
          }))
        : [];
      setUsers(usersWithWhitelist);
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
      setUsers([]); // Обнуляем состояние при ошибке
      setSnackbar({
        open: true,
        message: 'Ошибка при загрузке пользователей.',
        severity: 'error',
      });
    }
  };

  const handleAddWhitelist = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleDeleteWhitelist = async (userId, address) => {
    try {
      await axiosInstance.delete(`/users/${userId}/whitelist`, { data: { address } });
      // Обновление локального состояния
      setUsers(users.map((user) => {
        if (user.id === userId) {
          return { ...user, whitelist: user.whitelist.filter((addr) => addr !== address) };
        }
        return user;
      }));
      setSnackbar({
        open: true,
        message: 'Адрес успешно удалён из вайтлиста.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Ошибка при удалении адреса из вайтлиста:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при удалении адреса из вайтлиста.',
        severity: 'error',
      });
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setWhitelistAddress('');
    setSelectedUser(null);
  };

  const handleAddWhitelistAddress = async () => {
    if (!whitelistAddress.trim()) {
      setSnackbar({
        open: true,
        message: 'Адрес не может быть пустым.',
        severity: 'warning',
      });
      return;
    }

    try {
      await axiosInstance.post(`/users/${selectedUser.id}/whitelist`, { address: whitelistAddress });
      // Обновление локального состояния
      setUsers(users.map((user) => {
        if (user.id === selectedUser.id) {
          return { ...user, whitelist: [...user.whitelist, whitelistAddress] };
        }
        return user;
      }));
      setSnackbar({
        open: true,
        message: 'Адрес успешно добавлен в вайтлист.',
        severity: 'success',
      });
      handleDialogClose();
    } catch (error) {
      console.error('Ошибка при добавлении адреса в вайтлист:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при добавлении адреса в вайтлист.',
        severity: 'error',
      });
    }
  };

  // Функция для изменения пароля
  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbar({
        open: true,
        message: 'Новые пароли не совпадают.',
        severity: 'warning',
      });
      return;
    }

    try {
      await axiosInstance.post('/auth/change-password', {
        current_password: currentPassword,
        new_password: newPassword,
      });
      setSnackbar({
        open: true,
        message: 'Пароль успешно изменен.',
        severity: 'success',
      });
      // Сброс полей
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Ошибка при изменении пароля:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при изменении пароля. Проверьте текущий пароль и попробуйте снова.',
        severity: 'error',
      });
    }
  };

  // Функции для 2FA
  const handleSetup2FA = async (user) => {
    try {
      setLoading2FA(true);
      const response = await axiosInstance.post(`/users/${user.id}/2fa/setup`);
      setTwoFAUser(user);
      setQrCodeUrl(response.data.qrCodeUrl); // Используем qrCodeUrl как Data URL изображения
      setTwoFADialogOpen(true);
      setSnackbar({
        open: true,
        message: 'Пожалуйста, отсканируйте QR-код и введите OTP код.',
        severity: 'info',
      });
    } catch (error) {
      console.error('Ошибка при настройке 2FA:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при настройке 2FA. Попробуйте снова.',
        severity: 'error',
      });
    } finally {
      setLoading2FA(false);
    }
  };

  const handleVerify2FA = async () => {
    if (!otp.trim()) {
      setSnackbar({
        open: true,
        message: 'OTP код не может быть пустым.',
        severity: 'warning',
      });
      return;
    }

    try {
      await axiosInstance.post(`/users/${twoFAUser.id}/2fa/verify`, { otp });
      setSnackbar({
        open: true,
        message: 'Двухэтапная аутентификация успешно настроена.',
        severity: 'success',
      });
      setTwoFADialogOpen(false);
      setTwoFAUser(null);
      setOtp('');
      setQrCodeUrl('');
      fetchUsers(); // Обновить пользователей, чтобы отразить изменения
    } catch (error) {
      console.error('Ошибка при верификации 2FA:', error);
      setSnackbar({
        open: true,
        message: 'Неверный OTP код. Пожалуйста, попробуйте снова.',
        severity: 'error',
      });
    }
  };

  const handleDisable2FA = async () => {
    if (!otp.trim()) {
      setSnackbar({
        open: true,
        message: 'OTP код не может быть пустым.',
        severity: 'warning',
      });
      return;
    }

    try {
      await axiosInstance.post(`/users/${twoFAUser.id}/2fa/disable`, { otp });
      setSnackbar({
        open: true,
        message: 'Двухэтапная аутентификация успешно отключена.',
        severity: 'success',
      });
      setTwoFADialogOpen(false);
      setTwoFAUser(null);
      setOtp('');
      setQrCodeUrl('');
      fetchUsers(); // Обновить пользователей, чтобы отразить изменения
    } catch (error) {
      console.error('Ошибка при отключении 2FA:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при отключении 2FA. Проверьте OTP код и попробуйте снова.',
        severity: 'error',
      });
    }
  };

  const handle2FADialogClose = () => {
    setTwoFADialogOpen(false);
    setTwoFAUser(null);
    setOtp('');
    setQrCodeUrl('');
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box>
      {/* Раздел изменения пароля */}
      <Typography variant="h6" gutterBottom>
        Изменение пароля
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box display="flex" flexDirection="column" gap={2} maxWidth="400px">
        <TextField
          label="Текущий пароль"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          fullWidth
        />
        <TextField
          label="Новый пароль"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
        />
        <TextField
          label="Подтвердите новый пароль"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
        />
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleChangePassword}>
            Изменить пароль
          </Button>
        </Box>
      </Box>

      {/* Раздел управления пользователями */}
      <Box marginTop={5}>
        <Typography variant="h6" gutterBottom>
          Управление пользователями и вайтлист
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <List>
          {users.map((user) => (
            <ListItem key={user.id} divider>
              <ListItemText
                primary={user.username}
                secondary={
                  <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                    {user.whitelist && user.whitelist.length > 0 ? (
                      user.whitelist.map((address, index) => (
                        <Chip
                          key={index}
                          label={address}
                          onDelete={() => handleDeleteWhitelist(user.id, address)}
                        />
                      ))
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Нет вайтлист адресов.
                      </Typography>
                    )}
                  </Box>
                }
              />
              <Box display="flex" flexDirection="column" gap={1}>
                <Button variant="outlined" size="small" onClick={() => handleAddWhitelist(user)}>
                  Добавить адрес
                </Button>
                {!user.twoFAEnabled ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleSetup2FA(user)}
                    disabled={loading2FA && twoFAUser?.id === user.id}
                  >
                    {loading2FA && twoFAUser?.id === user.id ? <CircularProgress size={20} /> : 'Настроить 2FA'}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setTwoFAUser(user);
                      setTwoFADialogOpen(true);
                    }}
                  >
                    Отключить 2FA
                  </Button>
                )}
              </Box>
            </ListItem>
          ))}
          {users.length === 0 && (
            <Typography variant="body2" color="textSecondary" align="center" mt={2}>
              Нет доступных пользователей.
            </Typography>
          )}
        </List>
      </Box>

      {/* Диалог для добавления адреса в вайтлист */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Добавить адрес в вайтлист</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите адрес, с которого пользователь сможет войти в систему.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Адрес"
            type="text"
            fullWidth
            value={whitelistAddress}
            onChange={(e) => setWhitelistAddress(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Отмена
          </Button>
          <Button onClick={handleAddWhitelistAddress} color="primary">
            Добавить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог для настройки и отключения 2FA */}
      <Dialog open={twoFADialogOpen} onClose={handle2FADialogClose}>
        <DialogTitle>
          {twoFAUser && twoFAUser.twoFAEnabled ? 'Отключение двухэтапной аутентификации' : 'Настройка двухэтапной аутентификации'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {twoFAUser && twoFAUser.twoFAEnabled
              ? 'Введите OTP код для подтверждения отключения 2FA.'
              : 'Отсканируйте QR-код с помощью Google Authenticator и введите сгенерированный код.'}
          </DialogContentText>
          {!twoFAUser?.twoFAEnabled && qrCodeUrl && (
            <Box display="flex" justifyContent="center" my={2}>
              <img src={qrCodeUrl} alt="QR Code" style={{ width: '100%', maxWidth: '250px' }} />
            </Box>
          )}
          <TextField
            label="OTP код"
            type="text"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handle2FADialogClose} color="secondary">
            Отмена
          </Button>
          {twoFAUser && twoFAUser.twoFAEnabled ? (
            <Button onClick={handleDisable2FA} color="primary">
              Отключить 2FA
            </Button>
          ) : (
            <Button onClick={handleVerify2FA} color="primary">
              Подтвердить
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SecuritySettings;
