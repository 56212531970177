// src/components/ViewAffiliateModal.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Box,
  TextField,
  CircularProgress,
  Snackbar,
  Tooltip, // Добавлен импорт Tooltip
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import axiosInstance from '../utils/axiosInstance';
import { motion } from 'framer-motion';

const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ViewAffiliateModal = ({ open, handleClose, affiliate, refreshAffiliate }) => {
  const [regenerating, setRegenerating] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleRegenerateApiKey = async () => {
    if (!window.confirm('Вы уверены, что хотите регенерировать API ключ этого аффилиата?')) return;

    try {
      setRegenerating(true);
      const response = await axiosInstance.patch(`/affiliates/${affiliate.id}/regenerate-api-key`);
      const newApiKey = response.data.api_key;

      setSnackbar({
        open: true,
        message: 'API ключ успешно регенерирован.',
        severity: 'success',
      });

      // Обновляем данные аффилиата в родительском компоненте
      refreshAffiliate(affiliate.id, newApiKey);
    } catch (error) {
      console.error('Ошибка при регенерации API ключа:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при регенерации API ключа. Попробуйте позже.',
        severity: 'error',
      });
    } finally {
      setRegenerating(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (!affiliate) return null;

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Детали Аффилиата</DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Email:</strong> {affiliate.email}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Доверенные Сервера (Whitelists):</strong>
            </Typography>
            {affiliate.whitelists && affiliate.whitelists.length > 0 ? (
              <ul>
                {affiliate.whitelists.map((server, index) => (
                  <li key={index}>{server}</li>
                ))}
              </ul>
            ) : (
              <Typography variant="body2">Нет доверенных серверов.</Typography>
            )}
          </Box>
          <Box mb={2} display="flex" alignItems="center">
            <Typography variant="subtitle1" gutterBottom>
              <strong>API Ключ:</strong>
            </Typography>
            <Box ml={2} display="flex" alignItems="center">
              <TextField
                value={affiliate.api_key}
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
              <Tooltip title="Регенерировать API ключ">
                <span>
                  <IconButton
                    color="primary"
                    onClick={handleRegenerateApiKey}
                    disabled={regenerating}
                    sx={{ ml: 1 }}
                  >
                    {regenerating ? <CircularProgress size={24} /> : <RestartAltIcon />}
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertSnackbar onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </>
  );
};

ViewAffiliateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  affiliate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    whitelists: PropTypes.arrayOf(PropTypes.string),
    api_key: PropTypes.string.isRequired,
  }),
  refreshAffiliate: PropTypes.func.isRequired,
};

export default ViewAffiliateModal;
