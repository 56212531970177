// src/pages/Leads/FilterModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Divider,
  TextField, // Добавлено
} from '@mui/material';
import { motion } from 'framer-motion';
import axiosInstance from '../../utils/axiosInstance';
import { FaFilter, FaTimes } from 'react-icons/fa';
import Autocomplete from '@mui/material/Autocomplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterModal = ({ open, onClose, onApplyFilters }) => {
  // Состояния для выбранных фильтров
  const [selectedAffiliates, setSelectedAffiliates] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedRetainedStatuses, setSelectedRetainedStatuses] = useState([]);
  const [selectedSaleStatuses, setSelectedSaleStatuses] = useState([]);

  // Состояния для опций фильтров
  const [affiliates, setAffiliates] = useState([]);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [retainedStatuses, setRetainedStatuses] = useState([]);
  const [saleStatuses, setSaleStatuses] = useState([]);

  // Состояния загрузки
  const [loadingAffiliates, setLoadingAffiliates] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingRetainedStatuses, setLoadingRetainedStatuses] = useState(false);
  const [loadingSaleStatuses, setLoadingSaleStatuses] = useState(false);

  // Ошибки
  const [error, setError] = useState(null);

  // Состояние для переключения между основными и специальными фильтрами
  const [isSpecialFilters, setIsSpecialFilters] = useState(false);

  // Получение данных фильтров при открытии модального окна
  useEffect(() => {
    if (open) {
      fetchFilterOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchFilterOptions = async () => {
    try {
      setError(null);

      // Запуск загрузки
      setLoadingAffiliates(true);
      setLoadingTeams(true);
      setLoadingUsers(true);
      setLoadingCountries(true);
      setLoadingRetainedStatuses(true);
      setLoadingSaleStatuses(true);

      // Параллельные запросы для получения аффилиатов, команд, стран и статусов
      const [
        affiliatesRes,
        teamsRes,
        countriesRes,
        retainedStatusesRes,
        saleStatusesRes,
      ] = await Promise.all([
        axiosInstance.get('/affiliates'),
        axiosInstance.get('/teams'),
        axiosInstance.get('/countries'),
        axiosInstance.get('/statuses', { params: { type: 'retained' } }),
        axiosInstance.get('/statuses', { params: { type: 'sale' } }),
      ]);

      setAffiliates(affiliatesRes.data.affiliates || []);
      setTeams(teamsRes.data.teams || []);
      setCountries(countriesRes.data.countries || []);
      setRetainedStatuses(retainedStatusesRes.data.statuses || []);
      setSaleStatuses(saleStatusesRes.data.statuses || []);

      // Получение пользователей, фильтруемых по выбранным командам
      let usersRes;
      if (selectedTeams.length > 0) {
        usersRes = await axiosInstance.get('/users', {
          params: { team_ids: selectedTeams.join(',') },
        });
      } else {
        usersRes = await axiosInstance.get('/users');
      }
      setUsers(usersRes.data.users || []);
    } catch (err) {
      console.error('Ошибка при загрузке опций фильтра:', err);
      setError('Не удалось загрузить опции фильтра. Попробуйте позже.');
    } finally {
      setLoadingAffiliates(false);
      setLoadingTeams(false);
      setLoadingUsers(false);
      setLoadingCountries(false);
      setLoadingRetainedStatuses(false);
      setLoadingSaleStatuses(false);
    }
  };

  // Обновление списка пользователей при изменении выбранных команд
  useEffect(() => {
    const fetchUsersByTeams = async () => {
      try {
        setLoadingUsers(true);
        let url = '/users';
        let params = {};

        if (selectedTeams.length > 0) {
          params.team_ids = selectedTeams.join(',');
        }

        const response = await axiosInstance.get(url, { params });
        setUsers(response.data.users || []);

        // Очищаем выбранных пользователей, если они не входят в новый список
        setSelectedUsers((prevSelectedUsers) =>
          prevSelectedUsers.filter((userId) => response.data.users.some((user) => user.id === userId))
        );
      } catch (err) {
        console.error('Ошибка при загрузке пользователей:', err);
        setError('Не удалось загрузить пользователей. Попробуйте позже.');
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsersByTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeams]);

  // Обработчики изменений
  const handleAffiliateChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAffiliates(typeof value === 'string' ? value.split(',') : value);
  };

  const handleTeamChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTeams(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCountryChange = (event, value) => {
    setSelectedCountries(value);
  };

  const handleRetainedStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRetainedStatuses(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSaleStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSaleStatuses(typeof value === 'string' ? value.split(',') : value);
  };

  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(typeof value === 'string' ? value.split(',') : value);
  };

  const handleApplyFilters = () => {
    const filters = {
      affiliates: selectedAffiliates,
      teams: selectedTeams,
      users: selectedUsers,
      countries: selectedCountries.map((country) => country.id), // предполагается, что у объекта country есть поле id
      retainedStatuses: selectedRetainedStatuses,
      saleStatuses: selectedSaleStatuses,
    };
    onApplyFilters(filters);
    onClose();
  };

  const handleClearFilters = () => {
    setSelectedAffiliates([]);
    setSelectedTeams([]);
    setSelectedUsers([]);
    setSelectedCountries([]);
    setSelectedRetainedStatuses([]);
    setSelectedSaleStatuses([]);
  };

  const toggleFilterSection = () => {
    setIsSpecialFilters((prev) => !prev);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ position: 'relative' }}>
        {isSpecialFilters ? 'Специальные Фильтры' : 'Основные Фильтры'}
        <IconButton
          aria-label="toggle-filters"
          onClick={toggleFilterSection}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          {isSpecialFilters ? <FaTimes /> : <FaFilter />}
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {error && (
          <Box mb={2}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
        {!isSpecialFilters && (
          <>
            {/* Фильтр по аффилиатам */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="affiliate-label">Аффилиаты</InputLabel>
              <Select
                labelId="affiliate-label"
                multiple
                value={selectedAffiliates}
                onChange={handleAffiliateChange}
                input={<OutlinedInput label="Аффилиаты" />}
                renderValue={(selected) =>
                  affiliates
                    .filter((affiliate) => selected.includes(affiliate.id))
                    .map((affiliate) => affiliate.name)
                    .join(', ')
                }
                MenuProps={MenuProps}
              >
                {loadingAffiliates ? (
                  <MenuItem>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  affiliates.map((affiliate) => (
                    <MenuItem key={affiliate.id} value={affiliate.id}>
                      <Checkbox checked={selectedAffiliates.indexOf(affiliate.id) > -1} />
                      <ListItemText primary={affiliate.name} />
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            {/* Фильтр по командам */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="team-label">Команды</InputLabel>
              <Select
                labelId="team-label"
                multiple
                value={selectedTeams}
                onChange={handleTeamChange}
                input={<OutlinedInput label="Команды" />}
                renderValue={(selected) =>
                  teams
                    .filter((team) => selected.includes(team.id))
                    .map((team) => team.name)
                    .join(', ')
                }
                MenuProps={MenuProps}
              >
                {loadingTeams ? (
                  <MenuItem>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      <Checkbox checked={selectedTeams.indexOf(team.id) > -1} />
                      <ListItemText primary={team.name} />
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            {/* Фильтр по пользователям */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="user-label">Пользователи</InputLabel>
              <Select
                labelId="user-label"
                multiple
                value={selectedUsers}
                onChange={handleUserChange}
                input={<OutlinedInput label="Пользователи" />}
                renderValue={(selected) =>
                  users
                    .filter((user) => selected.includes(user.id))
                    .map((user) => user.username)
                    .join(', ')
                }
                MenuProps={MenuProps}
              >
                {loadingUsers ? (
                  <MenuItem>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      <Checkbox checked={selectedUsers.indexOf(user.id) > -1} />
                      <ListItemText primary={user.username} />
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </>
        )}
        {isSpecialFilters && (
          <>
            {/* Фильтр по странам */}
            <FormControl fullWidth margin="normal">
              <Autocomplete
                multiple
                options={countries}
                getOptionLabel={(option) => option.name}
                value={selectedCountries}
                onChange={handleCountryChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Страны"
                    placeholder="Выберите страны"
                  />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: '200px',
                  },
                }}
              />
            </FormControl>

            {/* Фильтр по статусам "Ретен" */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="retained-status-label">Статусы Ретен</InputLabel>
              <Select
                labelId="retained-status-label"
                multiple
                value={selectedRetainedStatuses}
                onChange={handleRetainedStatusChange}
                input={<OutlinedInput label="Статусы Ретен" />}
                renderValue={(selected) =>
                  retainedStatuses
                    .filter((status) => selected.includes(status.id))
                    .map((status) => status.name)
                    .join(', ')
                }
                MenuProps={MenuProps}
              >
                {loadingRetainedStatuses ? (
                  <MenuItem>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  retainedStatuses.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      <Checkbox checked={selectedRetainedStatuses.indexOf(status.id) > -1} />
                      <ListItemText primary={status.name} />
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            {/* Фильтр по статусам "Сейл" */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="sale-status-label">Статусы Сейл</InputLabel>
              <Select
                labelId="sale-status-label"
                multiple
                value={selectedSaleStatuses}
                onChange={handleSaleStatusChange}
                input={<OutlinedInput label="Статусы Сейл" />}
                renderValue={(selected) =>
                  saleStatuses
                    .filter((status) => selected.includes(status.id))
                    .map((status) => status.name)
                    .join(', ')
                }
                MenuProps={MenuProps}
              >
                {loadingSaleStatuses ? (
                  <MenuItem>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  saleStatuses.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      <Checkbox checked={selectedSaleStatuses.indexOf(status.id) > -1} />
                      <ListItemText primary={status.name} />
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button onClick={handleClearFilters} color="secondary">
                Сбросить
              </Button>
            </motion.div>
          </Box>
          <Box>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button onClick={onClose}>Отмена</Button>
            </motion.div>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button
                onClick={handleApplyFilters}
                variant="contained"
                color="primary"
                sx={{ ml: 1 }}
              >
                Применить
              </Button>
            </motion.div>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
