// src/pages/BusinessUnits/AffiliateDetails.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../utils/axiosInstance';

const AffiliateDetails = () => {
  const { token } = useAuth();
  const { affiliateId } = useParams();
  const [affiliate, setAffiliate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!token) return; // Предполагается, что маршруты защищены

    const fetchAffiliateDetails = async () => {
      try {
        const response = await axiosInstance.get(`/affiliates/${affiliateId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAffiliate(response.data);
      } catch (err) {
        console.error('Ошибка при загрузке деталей аффилиата:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliateDetails();
  }, [token, affiliateId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !affiliate) {
    return (
      <Box p={3}>
        <Alert severity="error">Ошибка загрузки деталей аффилиата.</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Детали Аффилиата: {affiliate.username}
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6">Токен Аффилиата</Typography>
        <Typography variant="body1" sx={{ wordBreak: 'break-all', mt: 1 }}>
          {affiliate.token}
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6">Доверенные Белые IP-адреса</Typography>
        {affiliate.whitelisted_ips && affiliate.whitelisted_ips.length > 0 ? (
          <List>
            {affiliate.whitelisted_ips.map((ip, index) => (
              <ListItem key={index}>
                <ListItemText primary={ip} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" sx={{ mt: 1 }}>
            Нет доверенных IP-адресов.
          </Typography>
        )}
      </Paper>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6">PDF Документация</Typography>
        <Button
          variant="contained"
          color="primary"
          href="/docs/affiliate_integration_guide.pdf"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ mt: 2 }}
        >
          Скачать Руководство по Интеграции
        </Button>
      </Paper>
    </Box>
  );
};

export default AffiliateDetails;
