// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client'; // Импортируем из 'react-dom/client'
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Создаём корень с помощью createRoot

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <CssBaseline />
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
