// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ allowedRoles, children }) => {
  const { auth, hasRole } = useAuth();

  console.log('--- ProtectedRoute ---');
  console.log('Токен пользователя:', auth.token);
  console.log('Роли пользователя:', auth.roles); // <- Важно! Тут должно быть auth.roles, не user.roles или что-то другое
  console.log('Разрешённые роли для маршрута:', allowedRoles);

  if (auth.loading) {
    console.log('Данные о пользователе загружаются...');
    return <div>Загрузка...</div>;
  }

  if (!auth.token) {
    console.log('Пользователь не аутентифицирован. Перенаправление на /login');
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && allowedRoles.length > 0) {
    const hasAccess = allowedRoles.some((role) => hasRole(role));
    console.log('Пользователь имеет доступ:', hasAccess);

    if (!hasAccess) {
      console.log('Пользователь не имеет необходимой роли. Перенаправление на /unauthorized');
      return <Navigate to="/unauthorized" replace />;
    }
  }

  console.log('Доступ разрешён. Отображение компонента.');
  return children;
};

export default ProtectedRoute;
