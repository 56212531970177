// src/pages/Chats/CreateChatModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  Typography,
  CircularProgress,
} from '@mui/material';
import axios from '../../utils/axiosInstance';
import { useAuth } from '../../context/AuthContext';

const CreateChatModal = ({ open, onClose, onCreate }) => {
  const { auth } = useAuth();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [chatName, setChatName] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [errorUsers, setErrorUsers] = useState(null);

  useEffect(() => {
    if (open) {
      const fetchUsers = async () => {
        try {
          setLoadingUsers(true);
          const response = await axios.get('/users'); // Получаем пользователей из офиса
          console.log('Ответ от API /users:', response.data); // Логирование для отладки

          // Проверяем, является ли ответ массивом
          if (Array.isArray(response.data)) {
            setUsers(response.data);
            console.log('Загруженные пользователи:', response.data);
          } else if (response.data && Array.isArray(response.data.users)) {
            // Если API возвращает { users: [...] }
            setUsers(response.data.users);
            console.log('Загруженные пользователи:', response.data.users);
          } else {
            setUsers([]);
            console.log('Нет пользователей для отображения.');
          }
        } catch (error) {
          console.error('Ошибка при загрузке пользователей:', error);
          setErrorUsers('Не удалось загрузить пользователей.');
        } finally {
          setLoadingUsers(false);
        }
      };

      fetchUsers();
    }
  }, [open]);

  const handleCreate = async () => {
    if (selectedUsers.length === 0) {
      alert('Выберите хотя бы одного участника.');
      return;
    }

    if (chatName.trim() === '') {
      alert('Введите название чата.');
      return;
    }

    try {
      const participantIds = selectedUsers.map(user => user.id);
      const response = await axios.post('/chats', {
        participantIds,
        name: chatName,
      });

      if (response.data && response.data.chat) {
        onCreate(response.data.chat);
        onClose();
        // Сброс состояния
        setSelectedUsers([]);
        setChatName('');
      } else {
        alert('Чат создан, но ответ не в ожидаемом формате.');
      }
    } catch (error) {
      console.error('Ошибка при создании чата:', error);
      alert('Не удалось создать чат. Возможно, у вас недостаточно прав.');
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedUsers([]);
    setChatName('');
    setErrorUsers(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Создать новый чат</DialogTitle>
      <DialogContent>
        <TextField
          label="Название чата"
          value={chatName}
          onChange={(e) => setChatName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
          Выберите участников:
        </Typography>
        {loadingUsers ? (
          <CircularProgress />
        ) : errorUsers ? (
          <Typography color="error">{errorUsers}</Typography>
        ) : users.length > 0 ? (
          <Autocomplete
            multiple
            options={users}
            getOptionLabel={(option) => `${option.username} (${option.email})`}
            value={selectedUsers}
            onChange={(event, newValue) => {
              setSelectedUsers(newValue);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <input type="checkbox" checked={selected} readOnly style={{ marginRight: 8 }} />
                {option.username} ({option.email})
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Выберите пользователей"
              />
            )}
          />
        ) : (
          <Typography>Нет пользователей для отображения.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button onClick={handleCreate} color="primary" variant="contained">
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateChatModal;
