// src/pages/Leads/AddLeadModal.js

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import './AddLeadModal.css';

const AddLeadModal = ({ onClose, onAddLead }) => {
  const initialValues = {
    surname: '',
    first_name: '',
    patronymic: '',
    phone_number: '',
    email: '',
    country: '',
  };

  // Схема валидации с yup
  const validationSchema = Yup.object({
    surname: Yup.string().required('Фамилия обязательна'),
    first_name: Yup.string().required('Имя обязательно'),
    patronymic: Yup.string().required('Отчество обязательно'),
    phone_number: Yup.string()
      .matches(/^\d{10,11}$/, 'Номер должен содержать от 10 до 11 цифр')
      .required('Номер телефона обязателен'),
    email: Yup.string().email('Неверный формат email').required('Email обязателен'),
    country: Yup.string().required('Страна обязательна'),
  });

  const handleSubmit = (values, { resetForm }) => {
    if (typeof onAddLead !== 'function') {
      console.error('onAddLead is not a function:', onAddLead);
      return;
    }
    onAddLead(values);
    resetForm();
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Добавить нового лида</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Фамилия"
                  name="surname"
                  value={values.surname}
                  onChange={handleChange}
                  error={touched.surname && Boolean(errors.surname)}
                  helperText={touched.surname && errors.surname}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Имя"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Отчество"
                  name="patronymic"
                  value={values.patronymic}
                  onChange={handleChange}
                  error={touched.patronymic && Boolean(errors.patronymic)}
                  helperText={touched.patronymic && errors.patronymic}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Номер телефона"
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  error={touched.phone_number && Boolean(errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Страна"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Box>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Отмена
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  Добавить Лид
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddLeadModal;
