// src/components/WidgetSelectorModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Divider,
} from '@mui/material';
import axiosInstance from '../utils/axiosInstance';
import ClockWidget from './ClockWidget';
import ToDoListWidget from './ToDoListWidget';
import CalendarWidget from './CalendarWidget';
import { motion } from 'framer-motion';
import './WidgetSelectorModal.css'; // Создайте этот файл для дополнительной стилизации

const availableWidgets = [
  { name: 'Clock', label: 'Часы', component: ClockWidget },
  { name: 'ToDoList', label: 'To-Do List', component: ToDoListWidget },
  { name: 'Calendar', label: 'Календарь', component: CalendarWidget },
];

const WidgetSelectorModal = ({ open, handleClose, refreshDashboard }) => {
  const [selectedWidgets, setSelectedWidgets] = useState([]);

  useEffect(() => {
    if (open) {
      fetchSelectedWidgets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchSelectedWidgets = async () => {
    try {
      const response = await axiosInstance.get('/widgets/user/widgets');
      setSelectedWidgets(response.data);
    } catch (error) {
      console.error('Ошибка при получении выбранных виджетов:', error);
    }
  };

  const handleCheckboxChange = (widgetName) => {
    if (selectedWidgets.includes(widgetName)) {
      setSelectedWidgets(selectedWidgets.filter(w => w !== widgetName));
    } else {
      setSelectedWidgets([...selectedWidgets, widgetName]);
    }
  };

  const handleSave = async () => {
    try {
      await axiosInstance.post('/widgets/user/widgets', { widgets: selectedWidgets });
      refreshDashboard();
      handleClose();
    } catch (error) {
      console.error('Ошибка при сохранении виджетов:', error);
    }
  };

  const renderWidgetPreview = (widgetName) => {
    const widget = availableWidgets.find(w => w.name === widgetName);
    if (!widget) return null;
    const WidgetComponent = widget.component;
    return (
      <Grid item xs={12} sm={6} key={widgetName}>
        <Box mb={2}>
          <WidgetComponent styleVariant="preview" />
        </Box>
      </Grid>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="widget-selector-title"
      aria-describedby="widget-selector-description"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          boxShadow: 24,
          padding: '24px',
          borderRadius: '8px',
          width: '80%',
          maxWidth: '1000px',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Typography id="widget-selector-title" variant="h6" component="h2" gutterBottom>
          Выбор Виджетов
        </Typography>
        <Divider />
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              Доступные Виджеты
            </Typography>
            {availableWidgets.map((widget) => (
              <FormControlLabel
                key={widget.name}
                control={
                  <Checkbox
                    checked={selectedWidgets.includes(widget.name)}
                    onChange={() => handleCheckboxChange(widget.name)}
                    name={widget.name}
                    color="primary"
                  />
                }
                label={widget.label}
              />
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              Предпросмотр Виджетов
            </Typography>
            <Grid container spacing={2}>
              {selectedWidgets.length > 0 ? (
                selectedWidgets.map((widgetName) => renderWidgetPreview(widgetName))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Выберите виджеты, чтобы увидеть их предпросмотр.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
          <Button variant="outlined" onClick={handleClose}>
            Отмена
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Сохранить
          </Button>
        </Box>
      </motion.div>
    </Modal>
  );
};

export default WidgetSelectorModal;
