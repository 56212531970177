// backend/utils/roles.js

const ROLES = {
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  SALE: 'sale',
  RETENTION: 'retention',
  SUPPORT: 'support',
  AFFILIATE: 'affiliate',
  };

module.exports = ROLES;
