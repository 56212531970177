// src/pages/Chats/MessageItem.js
import React from 'react';
import './MessageItem.css';
import { useAuth } from '../../context/AuthContext';

const MessageItem = ({ message }) => {
  const { auth } = useAuth();
  const isOwnMessage = message.sender_id === auth.user.id; // Проверка своего сообщения по sender_id

  return (
    <div className={`message-item ${isOwnMessage ? 'own' : ''}`}>
      {!isOwnMessage && <div className="message-sender">{message.sender?.username || 'Пользователь'}</div>}
      <div className="message-content">{message.content}</div>
      <div className="message-timestamp">{new Date(message.created_at).toLocaleTimeString()}</div>
    </div>
  );
};

export default MessageItem;
