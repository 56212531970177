// frontend/src/App.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './pages/Dashboard/Dashboard';
import Leads from './pages/Leads/Leads';
import LeadDetails from './pages/Leads/LeadDetails';
import Settings from './pages/Settings/Settings';
import Users from './pages/Users/Users';
import UserDetail from './pages/Users/UserDetail';
import Teams from './pages/BusinessUnits/Teams';
import TeamDetails from './pages/BusinessUnits/TeamDetails';
import Affiliates from './pages/BusinessUnits/Affiliates';
import AffiliateDetails from './pages/BusinessUnits/AffiliateDetails';
import CallCenters from './pages/BusinessUnits/CallCenters';
import EditOfficePage from './components/EditCallCenterModal';
import Support from './pages/Support/Support';
import AiChatPage from './pages/AiChat/AiChat';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import CreateChat from './pages/CreateChat';
import CreateLead from './pages/CreateLead';
import Calendar from './pages/Calendar/Calendar';
import ChatsPage from './pages/Chats/ChatsPage'; // Обновлено
import AffiliateLeadsPage from './pages/AffiliateLeads/AffiliateLeadsPage';
import './App.css';

const App = () => {
  const { auth } = useAuth();

  if (auth.loading) return <div>Загрузка...</div>;

  return (
    <div style={{ display: 'flex' }}>
      {auth.token && <Sidebar />}
      <main style={{ flexGrow: 1, padding: '24px' }}>
        <Routes>
          {/* Публичные маршруты */}
          <Route path="/login" element={<Login />} />

          {/* Защищённые маршруты */}
          <Route
            path="/logout"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <Logout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <Leads />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads/:id"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <LeadDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads/affiliates"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin']}>
                <AffiliateLeadsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead']}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin']}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/:id"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin']}>
                <UserDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/business-units/teams"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead']}>
                <Teams />
              </ProtectedRoute>
            }
          />
          <Route
            path="/business-units/teams/:teamId"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead']}>
                <TeamDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/business-units/affiliates"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin']}>
                <Affiliates />
              </ProtectedRoute>
            }
          />
          <Route
            path="/business-units/affiliates/:affiliateId"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin']}>
                <AffiliateDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/business-units/call-centers"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin']}>
                <CallCenters />
              </ProtectedRoute>
            }
          />
          <Route
            path="/offices/edit/:id"
            element={
              <ProtectedRoute allowedRoles={['superadmin']}>
                <EditOfficePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'teamlead']}>
                <Support />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ai-chat"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <AiChatPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-chat"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead']}>
                <CreateChat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-lead"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <CreateLead />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calendar"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <Calendar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chats"
            element={
              <ProtectedRoute allowedRoles={['superadmin', 'admin', 'teamlead', 'sale', 'retention']}>
                <ChatsPage />
              </ProtectedRoute>
            }
          />
          {/* Редирект для несуществующих маршрутов */}
          <Route path="*" element={<Navigate to={auth.token ? '/dashboard' : '/login'} replace />} />
        </Routes>
      </main>
    </div>
  );
};

export default App;
