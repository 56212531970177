// src/components/ImportLeadsModal.js

import React, { useState } from 'react';
import axiosInstance from '../../utils/axiosInstance'; // Используем настроенный экземпляр axios
import './ImportLeadsModal.css';
import { Button } from '@mui/material';

const ImportLeadsModal = ({ onClose, onImport }) => {
    const [file, setFile] = useState(null);
    const [previewData, setPreviewData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [importErrors, setImportErrors] = useState([]);

    // Определение обязательных полей
    const requiredFields = ['surname', 'first_name', 'phone_number', 'email', 'last_comment'];

    // Обработчик изменения файла
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        setError('');
        setImportErrors([]);
        setPreviewData([]);
        setColumns([]);

        if (!selectedFile) return;

        // Проверка типа файла
        const allowedTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel'
        ];
        if (!allowedTypes.includes(selectedFile.type)) {
            setError('Неподдерживаемый тип файла. Пожалуйста, выберите Excel-файл.');
            setFile(null);
            return;
        }

        setFile(selectedFile);
        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            console.log('Отправка запроса на предварительный просмотр...');
            const response = await axiosInstance.post('/leads/preview', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Ответ от сервера:', response.data);
            setPreviewData(response.data.data || []);
            setColumns(response.data.columns || []);

            // Проверка наличия обязательных столбцов
            const missingFields = requiredFields.filter(field => !response.data.columns.includes(field));
            if (missingFields.length > 0) {
                setError(`Отсутствуют обязательные столбцы: ${missingFields.join(', ')}`);
            }
        } catch (err) {
            console.error('Ошибка при предварительном просмотре:', err);
            setError(err.response?.data?.message || 'Ошибка при загрузке файла');
        } finally {
            setIsLoading(false);
        }
    };

    // Обработчик импорта данных
    const handleImport = async () => {
        console.log('Кнопка "Импортировать" нажата');
        setError('');
        setImportErrors([]);

        if (!file) {
            setError('Пожалуйста, выберите файл для импорта');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', file);

        try {
            console.log('Отправка запроса на импорт данных...');
            const response = await axiosInstance.post('/leads/import', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Импорт успешно выполнен:', response.data);
            onImport();
            onClose();
        } catch (err) {
            console.error('Ошибка при импорте данных:', err);
            if (err.response?.data?.errors) {
                setImportErrors(err.response.data.errors);
            }
            setError(err.response?.data?.message || 'Ошибка при импорте данных');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="import-modal-overlay">
            <div className="import-modal-content">
                <div className="import-modal-header">
                    <h2>Импорт лидов из Excel</h2>
                    <span className="import-modal-close" onClick={onClose}>&times;</span>
                </div>
                <div className="import-modal-body">
                    {error && <p className="error">{error}</p>}
                    {importErrors.length > 0 && (
                        <div className="import-errors">
                            <h4>Ошибки при импорте:</h4>
                            <ul>
                                {importErrors.map((err, idx) => (
                                    <li key={idx}>Строка {err.row}: {err.message}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {isLoading && <p>Загрузка...</p>}
                    <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />

                    {columns.length > 0 && (
                        <div className="import-preview-section">
                            <h3>Предварительный Просмотр</h3>
                            <table>
                                <thead>
                                    <tr>
                                        {columns.map((column) => (
                                            <th key={column}>{column}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {previewData.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {columns.map((column) => (
                                                <td key={column}>{row[column]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <p className="info-text">Убедитесь, что все обязательные столбцы присутствуют и имеют правильные названия.</p>
                        </div>
                    )}
                </div>
                <div className="import-modal-footer">
                    <Button className="import-btn-cancel" onClick={onClose} disabled={isLoading} variant="outlined">
                        Отмена
                    </Button>
                    <Button
                        className="import-btn-submit"
                        onClick={handleImport}
                        disabled={
                            !file ||
                            columns.length === 0 ||
                            isLoading ||
                            requiredFields.some(field => !columns.includes(field)) ||
                            error !== ''
                        }
                        variant="contained"
                        color="primary"
                    >
                        Импортировать
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ImportLeadsModal;
