// src/pages/BusinessUnits/Offices.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddOfficeModal from '../../components/AddCallCenterModal';
import FilterModal from '../../components/FilterModal/FilterModal';

const Offices = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/offices');
        setOffices(response.data);
      } catch (error) {
        setError('Ошибка при загрузке офисов. Попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchOffices();
  }, []);

  const handleDeleteOffice = async (officeId) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот офис?')) return;

    try {
      await axiosInstance.delete(`/offices/${officeId}`);
      setOffices((prev) => prev.filter((office) => office.id !== officeId));
    } catch (error) {
      alert('Ошибка при удалении офиса. Попробуйте позже.');
    }
  };

  const handleOpenEdit = (office) => {
    if (!office) {
      console.error('Office data is undefined:', office);
      return;
    }
    navigate(`/offices/edit/${office.id}`, { state: { office } });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Офисы
      </Typography>

      <Box mb={2} display="flex" justifyContent="space-between">
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsAddModalOpen(true)}
            style={{ marginRight: '8px' }}
          >
            Добавить Офис
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsFilterModalOpen(true)}
            style={{ marginRight: '8px' }}
          >
            Фильтр
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Описание</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offices.map((office) => (
              <TableRow key={office.id} hover>
                <TableCell>{office.id}</TableCell>
                <TableCell>{office.name}</TableCell>
                <TableCell>{office.description || 'Нет описания'}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpenEdit(office)}
                    style={{ marginRight: '8px' }}
                  >
                    Редактировать
                  </Button>
                  {(auth.roles.includes('superadmin') || auth.roles.includes('admin')) && (
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteOffice(office.id)}
                      aria-label="удалить"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Модальные окна */}
      {isAddModalOpen && <AddOfficeModal onClose={() => setIsAddModalOpen(false)} />}
      {isFilterModalOpen && <FilterModal onClose={() => setIsFilterModalOpen(false)} />}
    </Box>
  );
};

export default Offices;
