// src/pages/BusinessUnits/components/AddTeamModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Autocomplete,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useAuth } from '../context/AuthContext'; // Исправьте путь при необходимости
import axiosInstance from '../utils/axiosInstance'; // Исправьте путь при необходимости

const AddTeamModal = ({ open, handleClose, handleAddTeam, officeId }) => {
  const { token } = useAuth();
  const [newTeam, setNewTeam] = useState({
    name: '',
    leaderId: null,
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (open) {
      const fetchUsers = async () => {
        try {
          setLoadingUsers(true);
          const response = await axiosInstance.get('/users', {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log('Ответ от /users:', response.data); // Для отладки

          // Проверяем структуру ответа
          if (Array.isArray(response.data)) {
            setUsers(response.data);
          } else if (Array.isArray(response.data.users)) {
            setUsers(response.data.users);
          } else {
            console.error('Неверный формат данных от API:', response.data);
            setUsers([]);
          }
        } catch (error) {
          console.error('Ошибка загрузки пользователей:', error);
          setUsers([]);
        } finally {
          setLoadingUsers(false);
        }
      };
      fetchUsers();
    }
  }, [open, token]);

  const handleChange = (e) => {
    setNewTeam((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }));
  };

  const handleLeaderChange = (event, value) => {
    setNewTeam((prev) => ({ ...prev, leaderId: value ? value.id : null }));
    setErrors((prev) => ({ ...prev, leaderId: '' }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!newTeam.name) tempErrors.name = 'Введите название команды';
    if (!newTeam.leaderId) tempErrors.leaderId = 'Выберите лидера команды';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        setLoadingSubmit(true);
        setSubmitError(null);
        const response = await axiosInstance.post('/teams', {
          name: newTeam.name,
          leader_id: newTeam.leaderId,
          office_id: Number(officeId), // Обеспечиваем, что office_id является числом
        });

        const createdTeam = response.data; // Предполагается, что API возвращает созданную команду
        handleAddTeam(createdTeam); // Обновляем список команд в родительском компоненте
        setNewTeam({ name: '', leaderId: null });
        handleClose();
      } catch (error) {
        console.error('Ошибка при добавлении команды:', error);
        setSubmitError(
          error.response?.data?.errors?.[0]?.msg ||
            'Ошибка при добавлении команды. Проверьте правильность введённых данных.'
        );
      } finally {
        setLoadingSubmit(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Добавить новую команду</DialogTitle>
      <DialogContent>
        {submitError && (
          <Box mb={2}>
            <Alert severity="error">{submitError}</Alert>
          </Box>
        )}
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Название команды"
              name="name"
              value={newTeam.name}
              onChange={handleChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            {loadingUsers ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Autocomplete
                options={users}
                getOptionLabel={(option) => option.username || option.name || ''}
                onChange={handleLeaderChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Лидер команды"
                    error={!!errors.leaderId}
                    helperText={errors.leaderId}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loadingSubmit}>
          Отмена
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loadingSubmit}
        >
          {loadingSubmit ? <CircularProgress size={24} /> : 'Добавить команду'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTeamModal;
