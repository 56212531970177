// src/components/FilterModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, TextField, Box, Typography, MenuItem } from '@mui/material';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
  },
};

const FilterModal = ({ isOpen, onRequestClose, onApplyFilters }) => {
  const [filters, setFilters] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    status: '',
  });

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleApply = () => {
    onApplyFilters(filters);
    onRequestClose();
  };

  const handleReset = () => {
    setFilters({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      country: '',
      status: '',
    });
    onApplyFilters({});
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Фильтры"
    >
      <Typography variant="h6" gutterBottom>
        Фильтры Лидов
      </Typography>
      <Box component="form" noValidate>
        <TextField
          label="Имя"
          name="firstName"
          value={filters.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Фамилия"
          name="lastName"
          value={filters.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={filters.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Телефон"
          name="phone"
          value={filters.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Страна"
          name="country"
          value={filters.country}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Статус"
          name="status"
          value={filters.status}
          onChange={handleChange}
          fullWidth
          margin="normal"
          select
        >
          <MenuItem value="">Все</MenuItem>
          <MenuItem value="new">Новый</MenuItem>
          <MenuItem value="contacted">Связался</MenuItem>
          <MenuItem value="converted">Конвертирован</MenuItem>
          {/* Добавьте другие статусы по необходимости */}
        </TextField>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleReset} color="secondary" style={{ marginRight: '10px' }}>
            Сбросить
          </Button>
          <Button onClick={handleApply} variant="contained" color="primary">
            Применить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterModal;
