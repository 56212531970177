// src/components/CalendarWidget.js

import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Box, Typography, Modal, TextField, Button, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import axiosInstance from '../utils/axiosInstance';
import './CalendarWidget.css'; // Для дополнительной стилизации

const CalendarWidget = () => {
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: '',
    end: '',
  });

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axiosInstance.get('/events'); // Предполагаемый API эндпоинт
      const formattedEvents = response.data.map(event => ({
        id: event.id,
        title: event.title,
        start: event.start_time,
        end: event.end_time,
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Ошибка при загрузке событий:', error);
    }
  };

  const handleDateClick = (arg) => {
    setNewEvent({ ...newEvent, start: arg.dateStr, end: arg.dateStr });
    setOpenModal(true);
  };

  const handleEventClick = (clickInfo) => {
    if (window.confirm(`Удалить событие '${clickInfo.event.title}'?`)) {
      axiosInstance.delete(`/events/${clickInfo.event.id}`)
        .then(() => {
          setEvents(events.filter(e => e.id !== clickInfo.event.id));
        })
        .catch(error => {
          console.error('Ошибка при удалении события:', error);
        });
    }
  };

  const handleSubmit = async () => {
    if (!newEvent.title.trim()) {
      alert('Название события обязательно.');
      return;
    }

    try {
      const response = await axiosInstance.post('/events', newEvent);
      setEvents([...events, response.data]);
      setOpenModal(false);
      setNewEvent({ title: '', start: '', end: '' });
    } catch (error) {
      console.error('Ошибка при создании события:', error);
    }
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Paper elevation={3} sx={{ p: 2, borderRadius: 2, height: '100%' }}>
        <Typography variant="h6" gutterBottom>
          📅 Календарь
        </Typography>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          height="auto"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          buttonText={{
            today: 'Сегодня',
            month: 'Месяц',
            week: 'Неделя',
            day: 'День',
          }}
        />

        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              boxShadow: 24,
              padding: '24px',
              borderRadius: '8px',
              width: '400px',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Новое Событие
            </Typography>
            <TextField
              label="Название"
              fullWidth
              margin="normal"
              value={newEvent.title}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Начало"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={newEvent.start}
              onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Конец"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={newEvent.end}
              onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button variant="outlined" onClick={() => setOpenModal(false)}>
                Отмена
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Создать
              </Button>
            </Box>
          </motion.div>
        </Modal>
      </Paper>
    </motion.div>
  );
};

export default CalendarWidget;
