// frontend/src/pages/Settings/StatusModal.js

import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import axiosInstance from '../../utils/axiosConfig'; // Убедитесь, что путь правильный

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const StatusModal = ({ open, onClose, onStatusCreated, offices }) => {
  const [name, setName] = useState('');
  const [color, setColor] = useState('#000000');
  const [officeId, setOfficeId] = useState('');

  const handleSubmit = async () => {
    if (!name.trim() || !officeId) {
      // Добавьте проверку на пустые значения
      alert('Пожалуйста, заполните все поля.');
      return;
    }

    try {
      const response = await axiosInstance.post('/statuses', {
        name,
        color,
        office_id: officeId,
      });
      onStatusCreated(response.data.status); // Убедитесь, что ответ содержит `status`
      // Сброс полей
      setName('');
      setColor('#000000');
      setOfficeId('');
    } catch (error) {
      console.error('Ошибка при создании статуса:', error);
      alert('Ошибка при создании статуса. Попробуйте снова.');
    }
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-status-title"
      aria-describedby="modal-status-description"
    >
      <Box sx={style}>
        <Typography id="modal-status-title" variant="h6" component="h2" gutterBottom>
          Добавить новый статус
        </Typography>
        <TextField
          label="Название статуса"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box marginY={2}>
          <Typography variant="body1" gutterBottom>
            Выберите цвет:
          </Typography>
          <SketchPicker color={color} onChangeComplete={handleColorChange} />
        </Box>
        <TextField
          select
          label="Выберите офис"
          value={officeId}
          onChange={(e) => setOfficeId(e.target.value)}
          fullWidth
          margin="normal"
        >
          {offices.map((office) => (
            <MenuItem key={office.id} value={office.id}>
              {office.name}
            </MenuItem>
          ))}
          {offices.length === 0 && (
            <MenuItem disabled>
              <em>Нет доступных офисов</em>
            </MenuItem>
          )}
        </TextField>
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Button onClick={onClose} color="secondary" style={{ marginRight: '8px' }}>
            Отмена
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default StatusModal;
