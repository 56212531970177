// src/pages/Chats/ChatsPage.js

import React, { useEffect, useState } from 'react';
import axios from '../../utils/axiosInstance';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import './ChatsPage.css';
import { useAuth } from '../../context/AuthContext';
import CreateChatModal from './CreateChatModal';
import { Button } from '@mui/material';

const ChatsPage = () => {
  const { auth } = useAuth();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [loadingChats, setLoadingChats] = useState(true);
  const [errorChats, setErrorChats] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchChats = async () => {
      if (!auth.token) {
        setErrorChats('Вы не авторизованы. Пожалуйста, войдите.');
        setLoadingChats(false);
        return;
      }

      try {
        setLoadingChats(true);
        const response = await axios.get('/chats');
        // Ожидаем, что бэк вернёт что-то типа { chats: [...] }
        if (response.data && Array.isArray(response.data.chats)) {
          setChats(response.data.chats);
        } else {
          setChats([]);
        }
      } catch (error) {
        console.error('Ошибка при загрузке чатов:', error);
        setErrorChats('Не удалось загрузить чаты. Возможно, у вас недостаточно прав.');
      } finally {
        setLoadingChats(false);
      }
    };

    fetchChats();
  }, [auth.token]);

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
  };

  const handleNewChat = () => {
    setIsModalOpen(true);
  };

  const handleCreateChat = (newChat) => {
    setChats(prev => [newChat, ...prev]);
    setSelectedChat(newChat);
  };

  const handleTogglePin = async (chat) => {
    if (!auth.token) {
      alert('Вы не авторизованы. Пожалуйста, войдите.');
      return;
    }

    const updatedChat = { ...chat, isPinned: !chat.isPinned };

    try {
      // PATCH-запрос для обновления isPinned
      await axios.patch(`/chats/${chat.id}`, { isPinned: updatedChat.isPinned });
      setChats(prev => prev.map(c => c.id === chat.id ? updatedChat : c));
      if (selectedChat && selectedChat.id === chat.id) {
        setSelectedChat(updatedChat);
      }
    } catch (error) {
      console.error('Ошибка при обновлении isPinned:', error);
      alert('Не удалось изменить закрепление чата. Возможно, у вас недостаточно прав.');
    }
  };

  const handleDeleteChat = async (chat) => {
    if (!auth.token) {
      alert('Вы не авторизованы. Пожалуйста, войдите.');
      return;
    }

    if (!window.confirm(`Вы уверены, что хотите удалить чат "${chat.name || 'Чат'}"?`)) {
      return;
    }

    try {
      await axios.delete(`/chats/${chat.id}`);
      setChats(prev => prev.filter(c => c.id !== chat.id));
      if (selectedChat && selectedChat.id === chat.id) {
        setSelectedChat(null);
      }
    } catch (error) {
      console.error('Ошибка при удалении чата:', error);
      alert('Не удалось удалить чат. Возможно, у вас недостаточно прав.');
    }
  };

  return (
    <div className="chats-page">
      <div className="chat-list-container">
        <div className="chat-list-header">
          <h2>Чаты</h2>
          <Button variant="contained" onClick={handleNewChat}>
            Создать чат
          </Button>
        </div>
        {loadingChats ? (
          <div>Загрузка чатов...</div>
        ) : errorChats ? (
          <div className="error">{errorChats}</div>
        ) : (
          <ChatList
            chats={chats}
            onSelect={handleChatSelect}
            selectedChat={selectedChat}
            onTogglePin={handleTogglePin}
            onDeleteChat={handleDeleteChat}
          />
        )}
      </div>
      <div className="chat-window-container">
        {selectedChat ? (
          <ChatWindow chat={selectedChat} />
        ) : (
          <div className="no-chat-selected">Выберите чат для просмотра сообщений.</div>
        )}
      </div>
      <CreateChatModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateChat}
      />
    </div>
  );
};

export default ChatsPage;
