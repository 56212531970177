// src/pages/Login/Login.js

import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import {
  Typography,
  TextField,
  Button,
  Alert,
  Box,
  Avatar,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import './Login.css';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const result = await login(form.email, form.password);
    setLoading(false);
    if (result.success) {
      navigate('/dashboard');
    } else {
      setError(result.message);
    }
  };

  return (
    <div className="login-page">
      <motion.div
        className="login-container"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
        exit={{ opacity: 0, y: 50 }}
      >
        {/* Логотип без анимации */}
        <Box display="flex" justifyContent="center" mb={3}>
          <Avatar
            sx={{ width: 120, height: 120 }}
            src="/logo.png"
            alt="CRM Logo"
          />
        </Box>

        {/* Заголовок */}
        <Typography variant="h4" component="h1" gutterBottom align="center" color="#2c3e50">
          Вход в CRM
        </Typography>

        {/* Ошибка */}
        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Alert severity="error" sx={{ mb: 2, fontSize: '1.1rem' }}>
              {error}
            </Alert>
          </motion.div>
        )}

        {/* Форма входа */}
        <Box component="form" onSubmit={handleSubmit} className="login-form">
          {/* Поле Email */}
          <Box className="form-group">
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaEnvelope className="input-icon-inner" />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputLabel-root': {
                  fontSize: '1.1rem',
                },
                '& .MuiInputBase-input': {
                  fontSize: '1.1rem',
                  paddingLeft: '10px',
                },
              }}
            />
          </Box>

          {/* Поле Пароля */}
          <Box className="form-group">
            <TextField
              label="Пароль"
              variant="outlined"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={form.password}
              onChange={handleChange}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaLock className="input-icon-inner" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputLabel-root': {
                  fontSize: '1.1rem',
                },
                '& .MuiInputBase-input': {
                  fontSize: '1.1rem',
                  paddingLeft: '10px',
                },
              }}
            />
          </Box>

          {/* Кнопка Входа с индикатором загрузки */}
          <motion.div
            whileHover={!loading && { scale: 1.02, boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)' }}
            whileTap={!loading && { scale: 0.98 }}
            transition={{ duration: 0.3 }}
            className="login-button"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              disableElevation
              disabled={loading}
              sx={{
                height: '50px',
                fontSize: '1.2rem',
                backgroundColor: '#2193b0',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#1a78a0',
                },
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Войти'}
            </Button>
          </motion.div>
        </Box>

        {/* Дополнительные ссылки */}
        <Box mt={4} className="additional-links">
          <a href="https://t.me/whoamiimho" target="_blank" rel="noopener noreferrer">
            Забыли пароль?
          </a>
        </Box>
      </motion.div>
    </div>
  );
};

export default Login;
