// src/pages/Users/EditUserModal.js

import React, { useEffect, useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Box, 
  CircularProgress, 
  Alert 
} from '@mui/material';
import { motion } from 'framer-motion';
import axiosInstance from '../../utils/axiosInstance';

const EditUserModal = ({ open, onClose, user, onUserUpdated }) => {
  const [formData, setFormData] = useState({
    username: user.username || '',
    email: user.email || '',
    role: user.userRole?.id || '',
    office: user.office?.id || '',
    team: user.team?.id || '',
    password: '',
  });

  const [offices, setOffices] = useState([]);
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');

  // Функция для загрузки доступных офисов, команд и ролей
  const fetchOptions = async () => {
    try {
      setLoading(true);
      // Загрузка офисов
      const officesResponse = await axiosInstance.get('/offices');
      setOffices(officesResponse.data);

      // Загрузка ролей
      const rolesResponse = await axiosInstance.get('/roles');
      setRoles(rolesResponse.data);

      // Загрузка команд, связанных с офисом пользователя
      if (user.office?.id) {
        const teamsResponse = await axiosInstance.get(`/offices/${user.office.id}/teams`);
        setTeams(teamsResponse.data);
      }
    } catch (err) {
      console.error('Ошибка при загрузке опций:', err);
      setError('Ошибка при загрузке данных. Попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // Обработчик изменения полей формы
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Если изменяется офис, загрузить соответствующие команды
    if (name === 'office') {
      loadTeams(value);
    }
  };

  // Функция для загрузки команд при выборе офиса
  const loadTeams = async (officeId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/offices/${officeId}/teams`);
      setTeams(response.data);
      setFormData((prev) => ({
        ...prev,
        team: '',
      }));
    } catch (err) {
      console.error('Ошибка при загрузке команд:', err);
      setError('Ошибка при загрузке команд. Попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  // Обработчик отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setSubmitError('');
    setSubmitSuccess('');

    // Подготовка данных для отправки
    const updateData = {
      username: formData.username,
      email: formData.email,
      role_id: formData.role,
      office_id: formData.office,
      team_id: formData.team,
    };

    if (formData.password) {
      updateData.password = formData.password;
    }

    try {
      const response = await axiosInstance.put(`/users/${user.id}`, updateData);
      setSubmitSuccess('Пользователь успешно обновлён.');
      onUserUpdated(response.data); // Обновить данные в родительском компоненте
      // Закрыть модальное окно через небольшую задержку, чтобы пользователь увидел сообщение об успехе
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (err) {
      console.error('Ошибка при обновлении пользователя:', err);
      setSubmitError('Ошибка при обновлении пользователя. Проверьте введённые данные.');
    } finally {
      setSubmitLoading(false);
    }
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Редактировать Пользователя</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Редактировать Пользователя</DialogTitle>
        <DialogContent>
          <Alert severity="error">{error}</Alert>
        </DialogContent>
        <DialogActions>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} transition={{ duration: 0.3 }}>
            <Button onClick={onClose} color="secondary">
              Закрыть
            </Button>
          </motion.div>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Редактировать Пользователя</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {/* Сообщения об ошибках и успехе */}
          {submitError && <Alert severity="error" sx={{ mb: 2 }}>{submitError}</Alert>}
          {submitSuccess && <Alert severity="success" sx={{ mb: 2 }}>{submitSuccess}</Alert>}

          {/* Поле Имени пользователя */}
          <TextField
            margin="dense"
            label="Имя пользователя"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
            required
          />

          {/* Поле Email */}
          <TextField
            margin="dense"
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />

          {/* Поле Роли */}
          <FormControl fullWidth margin="dense" required>
            <InputLabel id="role-label">Роль</InputLabel>
            <Select
              labelId="role-label"
              name="role"
              value={formData.role}
              label="Роль"
              onChange={handleChange}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Поле Офиса */}
          <FormControl fullWidth margin="dense" required>
            <InputLabel id="office-label">Офис</InputLabel>
            <Select
              labelId="office-label"
              name="office"
              value={formData.office}
              label="Офис"
              onChange={handleChange}
            >
              {offices.map((office) => (
                <MenuItem key={office.id} value={office.id}>
                  {office.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Поле Команды */}
          <FormControl fullWidth margin="dense" required>
            <InputLabel id="team-label">Команда</InputLabel>
            <Select
              labelId="team-label"
              name="team"
              value={formData.team}
              label="Команда"
              onChange={handleChange}
            >
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Поле Пароля */}
          <TextField
            margin="dense"
            label="Пароль"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            helperText="Оставьте поле пустым, если не хотите изменять пароль."
          />
        </DialogContent>
        <DialogActions>
          {/* Кнопка "Отмена" с анимацией */}
          <motion.div 
            whileHover={{ scale: 1.05 }} 
            whileTap={{ scale: 0.95 }} 
            transition={{ duration: 0.3 }}
          >
            <Button onClick={onClose} color="secondary">
              Отмена
            </Button>
          </motion.div>
          {/* Кнопка "Сохранить" с анимацией */}
          <motion.div 
            whileHover={{ scale: 1.05 }} 
            whileTap={{ scale: 0.95 }} 
            transition={{ duration: 0.3 }}
          >
            <Button type="submit" color="primary" variant="contained" disabled={submitLoading}>
              {submitLoading ? <CircularProgress size={24} /> : 'Сохранить'}
            </Button>
          </motion.div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditUserModal;
