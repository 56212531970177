// src/components/ClockWidget.js

import React, { useState, useEffect } from 'react';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ClockWidget = () => {
  const [value, setValue] = useState(new Date());
  const [timezone, setTimezone] = useState('UTC');

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  // Преобразование времени в выбранный часовой пояс
  const getTimeInTimezone = () => {
    return new Date(new Date().toLocaleString("en-US", { timeZone: timezone }));
  };

  return (
    <Box textAlign="center" p={2}>
      <Typography variant="h6" gutterBottom>
        🕒 Часы ({timezone})
      </Typography>
      <Clock value={getTimeInTimezone()} size={150} renderNumbers />
      <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2 }}>
        <InputLabel>Часовой пояс</InputLabel>
        <Select value={timezone} label="Часовой пояс" onChange={handleTimezoneChange}>
          {Intl.supportedValuesOf('timeZone').map((tz) => (
            <MenuItem key={tz} value={tz}>
              {tz}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ClockWidget;
