// src/pages/Leads/LeadDetails.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Tooltip,
  Tabs,
  Tab,
  Grid,
  InputAdornment,
  MenuItem,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  AddComment as AddCommentIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { motion } from 'framer-motion';
import moment from 'moment';

// Компонент для Snackbar уведомлений
const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LeadDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { auth, affiliates, logout } = useAuth();
  const [lead, setLead] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedLead, setEditedLead] = useState({});
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    onConfirm: null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Состояния для статусов, агентов, команд и офисов
  const [statuses, setStatuses] = useState([]);
  const [agents, setAgents] = useState([]);
  const [teams, setTeams] = useState([]);
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    if (!auth.user) {
      navigate('/login');
    } else {
      fetchLeadDetails();
      fetchOffices(); // Получаем офисы при инициализации
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, auth.user]);

  // Функция для получения лидов
  const fetchLeadDetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/leads/${id}`);
      console.log('Lead details fetched:', response.data.lead);
      setLead(response.data.lead);
      setEditedLead({
        ...response.data.lead,
        status_id: response.data.lead.status ? response.data.lead.status.id : null,
        affiliate_id: response.data.lead.affiliate_id || null,
        company_id: response.data.lead.company ? response.data.lead.company.id : null,
        assigned_to_user_id: response.data.lead.assignedTo ? response.data.lead.assignedTo.id : null,
        team_id: response.data.lead.team ? response.data.lead.team.id : null,
        office_id: response.data.lead.office ? response.data.lead.office.id : null,
      });
      setComments(Array.isArray(response.data.lead.comments) ? response.data.lead.comments : []);
      setError(null);

      // Если лид имеет офис, загрузить статусы, агентов и команды этого офиса
      if (response.data.lead.office && response.data.lead.office.id) {
        await fetchStatuses(response.data.lead.office.id);
        await fetchDropdownData(response.data.lead.office.id);
      } else {
        console.warn('Лид не имеет связанного офиса');
        setStatuses([]);
        setAgents([]);
        setTeams([]);
      }
    } catch (error) {
      console.error('Ошибка при загрузке деталей лида:', error);
      if (error.response && error.response.status === 401) {
        logout();
        navigate('/login');
      } else if (error.response && error.response.status === 404) {
        setError('Лид не найден');
      } else {
        setError('Ошибка при загрузке данных лида');
      }
      setLead(null);
      setStatuses([]);
      setAgents([]);
      setTeams([]);
      setComments([]);
    } finally {
      setLoading(false);
    }
  };

  // Функция для получения статусов с параметром officeId
  const fetchStatuses = async (officeId) => {
    try {
      console.log('Запрос статусов с office_id:', officeId);
      const response = await axiosInstance.get('/statuses', {
        params: { office_id: officeId }, // Фильтрация по office_id лида
      });
      console.log('Statuses fetched:', response.data);

      // Проверка структуры ответа
      if (Array.isArray(response.data)) {
        setStatuses(response.data);
      } else if (response.data.statuses && Array.isArray(response.data.statuses)) {
        setStatuses(response.data.statuses);
      } else {
        console.warn('Неизвестная структура данных статусов:', response.data);
        setStatuses([]);
      }
    } catch (error) {
      console.error('Ошибка при загрузке статусов:', error);
      setSnackbar({ open: true, message: 'Ошибка при загрузке статусов.', severity: 'error' });
      setStatuses([]); // Обнуляем статусы в случае ошибки
    }
  };

  // Функция для получения офисов (если необходимо)
  const fetchOffices = async () => {
    try {
      const response = await axiosInstance.get('/offices', {
        params: { id: auth.user.office_id }, // Получаем только текущий офис
      });
      console.log('Offices fetched:', response.data);
      // Предполагаем, что ответ имеет поле 'offices'
      if (response.data.offices) {
        setOffices(response.data.offices);
      } else if (response.data.office) {
        setOffices([response.data.office]); // Если возвращается один офис
      } else {
        setOffices([]);
      }
    } catch (error) {
      console.error('Ошибка при загрузке офисов:', error);
      setSnackbar({ open: true, message: 'Ошибка при загрузке офисов.', severity: 'error' });
      setOffices([]); // Обнуляем офисы в случае ошибки
    }
  };

  // Функция для получения агентов и команд офиса
  const fetchDropdownData = async (officeId) => {
    try {
      // Получение агентов офиса
      const agentsResponse = await axiosInstance.get(`/offices/${officeId}/users`);
      console.log('Agents fetched:', agentsResponse.data);
      setAgents(Array.isArray(agentsResponse.data.users) ? agentsResponse.data.users : []);

      // Получение команд офиса
      const teamsResponse = await axiosInstance.get(`/offices/${officeId}/teams`);
      console.log('Teams fetched:', teamsResponse.data);
      setTeams(Array.isArray(teamsResponse.data.teams) ? teamsResponse.data.teams : []);
    } catch (error) {
      console.error('Ошибка при загрузке агентов и команд:', error);
      setSnackbar({ open: true, message: 'Ошибка при загрузке агентов и команд.', severity: 'error' });
      setAgents([]);
      setTeams([]);
    }
  };

  // Обработка изменений офиса при редактировании (если требуется)
  useEffect(() => {
    if (isEditing && editedLead.office_id) {
      fetchDropdownData(editedLead.office_id);
      fetchStatuses(editedLead.office_id); // Обновляем статусы при смене офиса
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, editedLead.office_id]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedLead({
        ...lead,
        status_id: lead.status ? lead.status.id : null,
        affiliate_id: lead.affiliate_id || null,
        company_id: lead.company ? lead.company.id : null,
        assigned_to_user_id: lead.assignedTo ? lead.assignedTo.id : null,
        team_id: lead.team ? lead.team.id : null,
        office_id: lead.office ? lead.office.id : null,
      });
    } else {
      setEditedLead({});
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let parsedValue = value;
    const fieldsToNullify = [
      'affiliate_id',
      'company_id',
      'assigned_to_user_id',
      'team_id',
      'office_id',
      'status_id',
    ];

    if (fieldsToNullify.includes(name)) {
      parsedValue = value === '' ? null : parseInt(value, 10);
    }

    setEditedLead((prev) => ({ ...prev, [name]: parsedValue }));
  };

  const handleSaveChanges = async () => {
    try {
      console.log('Saving changes:', editedLead);
      setSaving(true); // Начало сохранения

      // Список полей, которые нужно отправить
      const fieldsToSend = [
        'phone_number',
        'email',
        'surname',
        'first_name',
        'patronymic',
        'country',
        'city',
        'address',
        'postal_code',
        'date_of_birth',
        'status_id',
        'affiliate_id',
        'company_id',
        'assigned_to_user_id',
        'team_id',
        'office_id',
      ];

      // Фильтруем только те поля, которые нужно отправить и преобразуем пустые строки в null
      const payload = {};
      fieldsToSend.forEach((field) => {
        if (editedLead[field] !== undefined) {
          payload[field] = editedLead[field] === '' ? null : editedLead[field];
        }
      });

      console.log('Payload for update (sanitized):', payload);

      // Отправка запроса с отфильтрованными данными
      const response = await axiosInstance.put(`/leads/${id}`, payload);
      console.log('Response from save changes:', response.data);

      // Проверка, что ответ содержит обновлённый лид
      if (response.data.lead) {
        setLead(response.data.lead);
        setEditedLead({
          ...response.data.lead,
          status_id: response.data.lead.status ? response.data.lead.status.id : null,
          affiliate_id: response.data.lead.affiliate_id || null,
          company_id: response.data.lead.company ? response.data.lead.company.id : null,
          assigned_to_user_id: response.data.lead.assignedTo ? response.data.lead.assignedTo.id : null,
          team_id: response.data.lead.team ? response.data.lead.team.id : null,
          office_id: response.data.lead.office ? response.data.lead.office.id : null,
        });
        setComments(Array.isArray(response.data.lead.comments) ? response.data.lead.comments : []);
        setSnackbar({ open: true, message: 'Изменения успешно сохранены.', severity: 'success' });
      } else {
        throw new Error('Некорректный ответ от сервера');
      }

      setIsEditing(false);
    } catch (error) {
      console.error('Ошибка при сохранении изменений:', error);
      // Проверка, есть ли ответ от сервера с сообщением об ошибке
      if (error.response && error.response.data && error.response.data.message) {
        setSnackbar({ open: true, message: error.response.data.message, severity: 'error' });
      } else {
        setSnackbar({ open: true, message: 'Ошибка при сохранении изменений.', severity: 'error' });
      }
    } finally {
      setSaving(false); // Завершение сохранения
    }
  };

  const handleDeleteLead = () => {
    setConfirmDialog({
      open: true,
      title: 'Удалить Лид',
      content: 'Вы уверены, что хотите удалить этот лид? Это действие необратимо.',
      onConfirm: async () => {
        try {
          await axiosInstance.delete(`/leads/${id}`);
          setConfirmDialog({ ...confirmDialog, open: false });
          navigate('/leads');
          setSnackbar({ open: true, message: 'Лид успешно удален.', severity: 'success' });
        } catch (error) {
          console.error('Ошибка при удалении лида:', error);
          setSnackbar({ open: true, message: 'Ошибка при удалении лида.', severity: 'error' });
        }
      },
    });
  };

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
        await axiosInstance.post(`/leads/${id}/comments`, { text: newComment });
        setNewComment('');
        // Обновляем комментарии без полной перезагрузки лида
        setComments((prevComments) => [
          ...prevComments,
          {
            text: newComment,
            created_at: new Date().toISOString(),
            creator: { username: auth.user.username },
          },
        ]);
        setSnackbar({ open: true, message: 'Комментарий добавлен.', severity: 'success' });
      } catch (error) {
        console.error('Ошибка при добавлении комментария:', error);
        setSnackbar({ open: true, message: 'Ошибка при добавлении комментария.', severity: 'error' });
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Функция для получения имени аффилиата по affiliate_id или объекту affiliate
  const getAffiliateName = (affiliate_id, affiliate_obj) => {
    if (affiliate_obj && affiliate_obj.username) return affiliate_obj.username;
    if (affiliate_id) {
      const affiliate = affiliates.find((aff) => aff.id === affiliate_id);
      return affiliate ? affiliate.username : 'Не указан';
    }
    return '—';
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box padding={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!lead) {
    return (
      <Box padding={3}>
        <Alert severity="warning">Лид не найден</Alert>
      </Box>
    );
  }

  // Добавляем логи для проверки
  console.log('Отображение LeadDetails');
  console.log('Statuses:', statuses);
  console.log('Agents:', agents);
  console.log('Teams:', teams);
  console.log('Offices:', offices);
  console.log('Selected status_id:', isEditing ? editedLead.status_id : lead.status ? lead.status.id : null);

  return (
    <Box padding={3} maxWidth="1200px" margin="0 auto">
      <Typography variant="h4" gutterBottom>
        Карточка лида: {lead.id}
      </Typography>

      {/* Основной макет с тремя колонками */}
      <Grid container spacing={3}>
        {/* Колонка 1: Личная информация */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Typography variant="h6">Личная информация</Typography>
              <Box>
                <Tooltip title={isEditing ? "Сохранить изменения" : "Редактировать"}>
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <IconButton color="primary" onClick={isEditing ? handleSaveChanges : handleEditToggle} disabled={saving}>
                      {isEditing ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  </motion.div>
                </Tooltip>
                <Tooltip title="Удалить лид">
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <IconButton color="error" onClick={handleDeleteLead} disabled={saving}>
                      <DeleteIcon />
                    </IconButton>
                  </motion.div>
                </Tooltip>
              </Box>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />

            <Box display="flex" flexDirection="column" gap={2}>
              {/* Поля с иконками */}
              <TextField
                label="Имя"
                name="first_name"
                value={isEditing ? editedLead.first_name || '' : lead.first_name || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Фамилия"
                name="surname"
                value={isEditing ? editedLead.surname || '' : lead.surname || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Телефон"
                name="phone_number"
                value={isEditing ? editedLead.phone_number || '' : lead.phone_number || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Email"
                name="email"
                value={isEditing ? editedLead.email || '' : lead.email || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Страна"
                name="country"
                value={isEditing ? editedLead.country || '' : lead.country || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Отчество"
                name="patronymic"
                value={isEditing ? editedLead.patronymic || '' : lead.patronymic || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Последний комментарий"
                name="last_comment"
                value={isEditing ? editedLead.last_comment || '' : lead.last_comment || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Колонка 2: Дополнительная информация */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Дополнительная информация
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Статус"
                name="status_id"
                value={isEditing ? editedLead.status_id || '' : lead.status ? lead.status.id : ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {statuses && statuses.length > 0 ? (
                  statuses.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    Нет доступных статусов
                  </MenuItem>
                )}
              </TextField>
              <TextField
                label="Аффилиат"
                name="affiliate_id"
                value={isEditing ? editedLead.affiliate_id || '' : lead.affiliate ? lead.affiliate.id : ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select={isEditing}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {isEditing && affiliates && affiliates.length > 0 ? (
                  <>
                    <MenuItem value="">Выберите аффилиата</MenuItem>
                    {affiliates.map((aff) => (
                      <MenuItem key={aff.id} value={aff.id}>
                        {aff.username}
                      </MenuItem>
                    ))}
                  </>
                ) : !isEditing && lead.affiliate && lead.affiliate.username ? (
                  <MenuItem value={lead.affiliate.id} disabled>
                    {lead.affiliate.username}
                  </MenuItem>
                ) : (
                  <MenuItem value="" disabled>
                    Не указан
                  </MenuItem>
                )}
              </TextField>
              {/* Поле "Компании" */}
              <TextField
                label="Компании"
                name="company_id" // Убедитесь, что это соответствует вашему API
                value={isEditing ? editedLead.company_id || '' : lead.company ? lead.company.id : ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {offices && offices.length > 0 ? (
                  offices.map((office) => (
                    <MenuItem key={office.id} value={office.id}>
                      {office.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    Нет доступных компаний
                  </MenuItem>
                )}
              </TextField>
              {/* Поле "Назначен агент" */}
              <TextField
                label="Назначен агент"
                name="assigned_to_user_id"
                value={
                  isEditing
                    ? editedLead.assigned_to_user_id || ''
                    : lead.assignedTo && lead.assignedTo.username
                    ? lead.assignedTo.username
                    : 'Не назначен'
                }
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select={isEditing}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {isEditing && agents && agents.length > 0 ? (
                  <>
                    <MenuItem value="">Выберите агента</MenuItem>
                    {agents.map((agent) => (
                      <MenuItem key={agent.id} value={agent.id}>
                        {agent.username}
                      </MenuItem>
                    ))}
                  </>
                ) : !isEditing && lead.assignedTo && lead.assignedTo.username ? (
                  <MenuItem value={lead.assignedTo.id} disabled>
                    {lead.assignedTo.username}
                  </MenuItem>
                ) : (
                  <MenuItem value="" disabled>
                    Не назначен
                  </MenuItem>
                )}
              </TextField>
            </Box>
          </Paper>
        </Grid>

        {/* Колонка 3: Информация о лиде */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Информация о лиде
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="ID лида"
                name="id"
                value={lead.id}
                disabled
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Дата создания"
                name="created_at"
                value={lead.created_at ? moment(lead.created_at).format('DD.MM.YYYY HH:mm') : 'Не указано'}
                disabled
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Дата обновления"
                name="updated_at"
                value={lead.updated_at ? moment(lead.updated_at).format('DD.MM.YYYY HH:mm') : 'Не указано'}
                disabled
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Офис"
                name="office_id"
                value={lead.office ? lead.office.name : 'Не указан'}
                disabled
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Команда"
                name="team_id"
                value={lead.team ? lead.team.name : 'Не указана'}
                disabled
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Пароль"
                name="password"
                value={lead.password || ''}
                disabled
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Отправлен"
                name="sent_at"
                value={lead.sent_at ? moment(lead.sent_at).format('DD.MM.YYYY HH:mm') : 'Нет'}
                disabled
                variant="outlined"
                fullWidth
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Вкладки для дополнительных данных */}
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Комментарии" />
          {/* Добавьте другие вкладки по необходимости */}
        </Tabs>
        <Divider sx={{ marginY: 2 }} />

        {/* Содержимое вкладок */}
        {activeTab === 0 && (
          <Box>
            {/* Комментарии */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                <Typography variant="h6">Комментарии</Typography>
              </Box>
              <Divider sx={{ marginBottom: 2 }} />
              <List>
                {comments && comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2" color="textPrimary">
                            {comment.creator?.username || 'Неизвестный пользователь'}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" color="textPrimary">
                              {comment.text}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {moment(comment.created_at).format('DD.MM.YYYY HH:mm')}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">Нет комментариев</Typography>
                )}
              </List>

              <Divider sx={{ marginY: 2 }} />

              <Box display="flex" alignItems="center" gap={2}>
                <TextField
                  label="Новый комментарий"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  InputProps={{
                    style: { color: '#000' },
                  }}
                />
                <Tooltip title="Добавить комментарий">
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <IconButton color="primary" onClick={handleAddComment}>
                      <AddCommentIcon />
                    </IconButton>
                  </motion.div>
                </Tooltip>
              </Box>
            </Paper>
          </Box>
        )}

        {/* Добавьте содержимое других вкладок по необходимости */}
      </Paper>

      {/* Диалог подтверждения */}
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
      >
        <DialogTitle>{confirmDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} color="secondary">
            Отмена
          </Button>
          <Button onClick={confirmDialog.onConfirm} color="primary">
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertSnackbar severity={snackbar.severity} sx={{ width: '100%' }} onClose={handleCloseSnackbar}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>

      {/* Индикатор сохранения изменений */}
      {saving && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default LeadDetails;
