// src/pages/Leads/Leads.js

import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert as MuiAlert,
  Pagination,
  FormGroup,
  FormControlLabel,
  Popover,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import AddLeadModal from './AddLeadModal';
import ImportLeadsModal from './ImportLeadsModal';
import FilterModal from './FilterModal';
import { useNavigate } from 'react-router-dom';
import ROLES from '../../utils/roles';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import { motion } from 'framer-motion';

// Компонент для Snackbar уведомлений
const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Определение ролей по role_id
const SALE_ROLE_ID = 3;
const RETENTION_ROLE_ID = 4;

// Функция для обрезки текста до указанной длины
const truncateText = (text, maxLength) => {
  if (!text) return '—';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const Leads = () => {
  const { auth, hasRole } = useAuth();
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [affiliates, setAffiliates] = useState([]); // Добавляем состояние для аффилиатов
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Состояния для пагинации
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setLeadsPerPage] = useState(10); // По умолчанию 10 лидов на странице

  // Состояние для фильтров
  const [appliedFilters, setAppliedFilters] = useState({});

  // Состояние для выбора отображаемых столбцов
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    phone_number: true,
    email: true,
    surname: true,
    first_name: true,
    patronymic: true,
    country: true,
    last_comment: true,
    assignedTo: true,
    affiliate: true,
    office: true,
    created_at: true,
    updated_at: true,
    status: true,
    team: true,
    actions: true,
  });

  // Определение доступных столбцов
  const availableColumns = [
    { id: 'id', label: 'ID' },
    { id: 'phone_number', label: 'Номер Телефона' },
    { id: 'email', label: 'Email' },
    { id: 'surname', label: 'Фамилия' },
    { id: 'first_name', label: 'Имя' },
    { id: 'patronymic', label: 'Отчество' },
    { id: 'country', label: 'Страна' },
    { id: 'last_comment', label: 'Последний Комментарий' },
    { id: 'assignedTo', label: 'Назначен' },
    { id: 'affiliate', label: 'Аффилиат' },
    { id: 'office', label: 'Офис' },
    { id: 'created_at', label: 'Создан' },
    { id: 'updated_at', label: 'Обновлён' },
    { id: 'status', label: 'Статус' },
    { id: 'team', label: 'Команда' },
    { id: 'actions', label: 'Действия' },
  ];

  useEffect(() => {
    // Проверка аутентификации пользователя
    if (!auth.user) {
      navigate('/login');
    } else {
      fetchAffiliates(); // Получаем аффилиатов перед получением лидов
      fetchLeads();
      // Можно добавить другие начальные запросы, если необходимо
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  // Функция для получения аффилиатов
  const fetchAffiliates = async () => {
    try {
      const response = await axiosInstance.get('/affiliates'); // Убедитесь, что эндпоинт правильный
      console.log('Аффилиаты получены:', response.data.data);
      if (Array.isArray(response.data.data)) {
        setAffiliates(response.data.data);
      } else {
        console.warn('Неизвестная структура ответа от /affiliates:', response.data);
        setAffiliates([]);
      }
    } catch (error) {
      console.error('Ошибка при загрузке аффилиатов:', error);
      setSnackbar({ open: true, message: 'Ошибка при загрузке аффилиатов.', severity: 'error' });
      setAffiliates([]);
    }
  };

  // Функция для получения лидов
  const fetchLeads = async () => {
    try {
      const response = await axiosInstance.get('/leads'); // Убедитесь, что эндпоинт правильный
      console.log('Полный ответ от /leads:', response.data);
      console.log('Лиды из ответа:', Array.isArray(response.data) ? response.data : response.data.leads);

      // Проверка структуры ответа
      let fetchedLeads = [];
      if (Array.isArray(response.data)) {
        fetchedLeads = response.data;
      } else if (Array.isArray(response.data.leads)) {
        fetchedLeads = response.data.leads;
      } else {
        console.warn('Неизвестная структура ответа от /leads:', response.data);
        fetchedLeads = [];
      }

      // Сортировка лидов по дате создания в порядке убывания (последние лиды первыми)
      const sortedLeads = fetchedLeads.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      setLeads(sortedLeads);
    } catch (error) {
      console.error('Ошибка при загрузке лидов:', error);
      setError('Ошибка при загрузке лидов. Попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  // Функция для добавления нового лида
  const handleAddLead = async (leadData) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/leads', leadData);
      const newLead = response.data.lead; // Предполагается, что API возвращает созданный лид в поле 'lead'
      setLeads((prevLeads) => [newLead, ...prevLeads]); // Добавляем новый лид в начало списка
      setSnackbar({ open: true, message: 'Лид успешно добавлен.', severity: 'success' });
    } catch (error) {
      console.error('Ошибка при добавлении лида:', error);
      setSnackbar({ open: true, message: 'Ошибка при добавлении лида. Попробуйте позже.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Фильтрация лидов по поисковому запросу и применённым фильтрам
  const filteredLeads = useMemo(() => {
    let filtered = [...leads]; // Создаем копию массива для безопасной сортировки

    // Фильтрация по поисковому запросу
    if (searchTerm.trim()) {
      const lowerSearch = searchTerm.toLowerCase().trim();

      filtered = filtered.filter((lead) => {
        const {
          id,
          surname,
          first_name,
          patronymic,
          email,
          phone_number,
        } = lead;

        return (
          (id && id.toString().includes(lowerSearch)) ||
          (surname && surname.toLowerCase().includes(lowerSearch)) ||
          (first_name && first_name.toLowerCase().includes(lowerSearch)) ||
          (patronymic && patronymic.toLowerCase().includes(lowerSearch)) ||
          (email && email.toLowerCase().includes(lowerSearch)) ||
          (phone_number && phone_number.toLowerCase().includes(lowerSearch))
        );
      });
    }

    // Применение фильтров из FilterModal
    if (appliedFilters) {
      const { affiliates: filterAffiliates, teams, statuses, users: filterUsers } = appliedFilters;

      if (filterAffiliates && filterAffiliates.length > 0) {
        filtered = filtered.filter((lead) => filterAffiliates.includes(lead.affiliate_id));
      }

      if (teams && teams.length > 0) {
        filtered = filtered.filter((lead) => teams.includes(lead.team?.id));
      }

      if (statuses && statuses.length > 0) {
        filtered = filtered.filter((lead) => statuses.includes(lead.status?.id));
      }

      if (filterUsers && filterUsers.length > 0) {
        filtered = filtered.filter((lead) => filterUsers.includes(lead.assignedTo?.id));
      }
    }

    return filtered;
  }, [leads, searchTerm, appliedFilters]);

  // Расчёт индексов для текущей страницы
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  // Функция для удаления лида
  const handleDeleteLead = async (leadId) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот лид?')) return;

    try {
      await axiosInstance.delete(`/leads/${leadId}`);
      setLeads((prev) => prev.filter((lead) => lead.id !== leadId));
      setSnackbar({ open: true, message: 'Лид успешно удалён.', severity: 'success' });
    } catch (error) {
      console.error('Ошибка при удалении лида:', error);
      setSnackbar({ open: true, message: 'Ошибка при удалении лида. Попробуйте позже.', severity: 'error' });
    }
  };

  // Функция для блокировки лида
  const handleBlockLead = async (leadId) => {
    if (!window.confirm('Вы уверены, что хотите заблокировать этот лид?')) return;

    try {
      await axiosInstance.patch(`/leads/${leadId}/block`);
      setLeads((prev) =>
        prev.map((lead) =>
          lead.id === leadId ? { ...lead, blocked: true } : lead
        )
      );
      setSnackbar({ open: true, message: 'Лид успешно заблокирован.', severity: 'success' });
    } catch (error) {
      console.error('Ошибка при блокировке лида:', error);
      setSnackbar({ open: true, message: 'Ошибка при блокировке лида. Попробуйте позже.', severity: 'error' });
    }
  };

  // Функция для перенаправления на страницу деталей лида
  const handleRedirectToDetails = (leadId) => {
    navigate(`/leads/${leadId}`);
  };

  // Функция для выбора отдельного лида
  const handleSelectLead = (leadId) => {
    setSelectedLeads((prev) =>
      prev.includes(leadId) ? prev.filter((id) => id !== leadId) : [...prev, leadId]
    );
  };

  // Функция для выбора всех лидов на текущей странице
  const handleSelectAllLeads = (event) => {
    if (event.target.checked) {
      const allLeadIds = currentLeads.map((lead) => lead.id);
      setSelectedLeads((prevSelected) => [...new Set([...prevSelected, ...allLeadIds])]);
    } else {
      const currentLeadIds = currentLeads.map((lead) => lead.id);
      setSelectedLeads((prevSelected) => prevSelected.filter((id) => !currentLeadIds.includes(id)));
    }
  };

  // Проверка, выбраны ли все лиды на текущей странице
  const isAllSelected = currentLeads.length > 0 && currentLeads.every((lead) => selectedLeads.includes(lead.id));

  // Функция для открытия модального окна назначения лидов
  const handleOpenAssignModal = async () => {
    try {
      // Загрузка команд офиса
      const teamsResponse = await axiosInstance.get('/teams'); // Убедитесь, что эндпоинт правильный
      console.log('Ответ от /teams:', teamsResponse.data);

      // Проверка, является ли ответ массивом
      const fetchedTeams = Array.isArray(teamsResponse.data) ? teamsResponse.data : teamsResponse.data.teams;
      if (!Array.isArray(fetchedTeams)) {
        throw new Error('Некорректный формат данных команд');
      }

      setTeams(fetchedTeams);
      console.log('Загруженные команды:', fetchedTeams);

      if (fetchedTeams.length > 0) {
        setSelectedTeam(fetchedTeams[0].id);
        // Загрузка пользователей для первой команды
        const usersResponse = await axiosInstance.get(`/teams/${fetchedTeams[0].id}/users`);
        console.log(`Ответ от /teams/${fetchedTeams[0].id}/users:`, usersResponse.data);

        const fetchedUsers = Array.isArray(usersResponse.data) ? usersResponse.data : usersResponse.data.users;
        if (!Array.isArray(fetchedUsers)) {
          throw new Error('Некорректный формат данных пользователей');
        }

        // Фильтрация пользователей по ролям 'sale' и 'retention' на основе role_id
        const validUsers = fetchedUsers.filter(user => [SALE_ROLE_ID, RETENTION_ROLE_ID].includes(user.role_id));
        setUsers(validUsers);
        console.log('Загруженные пользователи для первой команды (sale и retention):', validUsers);

        if (validUsers.length === 0) {
          setSnackbar({ open: true, message: 'В выбранной команде нет пользователей с ролями sale или retention.', severity: 'info' });
        }
      } else {
        setSnackbar({ open: true, message: 'Нет доступных команд для назначения.', severity: 'warning' });
      }

      setIsAssignModalOpen(true);
    } catch (error) {
      console.error('Ошибка при загрузке команд или пользователей:', error);
      setSnackbar({ open: true, message: 'Ошибка при загрузке данных для назначения. Попробуйте позже.', severity: 'error' });
    }
  };

  // Функция для изменения команды при назначении
  const handleTeamChange = async (event) => {
    const teamId = event.target.value;
    setSelectedTeam(teamId);
    setSelectedUser('');

    try {
      const usersResponse = await axiosInstance.get(`/teams/${teamId}/users`);
      console.log(`Ответ от /teams/${teamId}/users:`, usersResponse.data);

      const fetchedUsers = Array.isArray(usersResponse.data) ? usersResponse.data : usersResponse.data.users;
      if (!Array.isArray(fetchedUsers)) {
        throw new Error('Некорректный формат данных пользователей');
      }

      // Фильтрация пользователей по ролям 'sale' и 'retention' на основе role_id
      const validUsers = fetchedUsers.filter(user => [SALE_ROLE_ID, RETENTION_ROLE_ID].includes(user.role_id));
      setUsers(validUsers);
      console.log(`Загруженные пользователи для команды ${teamId} (sale и retention):`, validUsers);

      if (validUsers.length === 0) {
        setSnackbar({ open: true, message: 'В выбранной команде нет пользователей с ролями sale или retention.', severity: 'info' });
      }
    } catch (error) {
      console.error('Ошибка при загрузке пользователей для выбранной команды:', error);
      setSnackbar({ open: true, message: 'Ошибка при загрузке пользователей. Попробуйте позже.', severity: 'error' });
    }
  };

  // Функция для изменения пользователя при назначении
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  // Функция для назначения лидов
  const handleAssignLeads = async () => {
    if (!selectedUser) {
      setSnackbar({ open: true, message: 'Пожалуйста, выберите пользователя для назначения.', severity: 'warning' });
      return;
    }

    try {
      setLoading(true);
      await axiosInstance.post('/leads/assign_bulk', {
        leadIds: selectedLeads,
        userId: Number(selectedUser),
      });

      // Получаем имя назначенного пользователя
      const assignedUser = users.find((user) => user.id === Number(selectedUser))?.username || 'Неизвестный пользователь';

      // Обновление состояния лидов
      setLeads((prev) =>
        prev.map((lead) =>
          selectedLeads.includes(lead.id)
            ? { ...lead, assignedTo: { username: assignedUser } }
            : lead
        )
      );

      setSnackbar({ open: true, message: `Лиды успешно назначены на ${assignedUser}.`, severity: 'success' });
      setSelectedLeads([]);
      setIsAssignModalOpen(false);
    } catch (error) {
      console.error('Ошибка при назначении лидов:', error);
      const errorMessage = error.response?.data?.message || 'Ошибка при назначении лидов. Попробуйте позже.';
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Функция для закрытия Snackbar уведомления
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Обработчики для выбора отображаемых столбцов
  const handleOpenColumnsPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColumnsPopover = () => {
    setAnchorEl(null);
  };

  const handleToggleColumn = (columnId) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const openColumnsPopover = Boolean(anchorEl);
  const idColumnsPopover = openColumnsPopover ? 'columns-popover' : undefined;

  // Проверка загрузки данных
  if (loading && affiliates.length === 0) { // Добавляем проверку загрузки аффилиатов
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  // Проверка на ошибки
  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Лиды
      </Typography>

      {/* Панель инструментов: поиск, добавление, импорт, фильтр, выбор столбцов */}
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        {/* Поле поиска */}
        <Box display="flex" alignItems="center" width="60%">
          {/* Добавлена анимация при наведении на поле поиска */}
          <motion.div
            whileHover={{ scale: 1.05, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}
            whileTap={{ scale: 0.95 }}
            style={{ width: '100%' }} // Обеспечивает, что анимированный блок занимает всю ширину
          >
            <TextField
              variant="outlined"
              placeholder="Поиск по ID, имени, фамилии, Email, телефону или Отчеству"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </motion.div>
        </Box>

        {/* Кнопки действий */}
        <Box display="flex" alignItems="center">
          {/* Кнопка назначения на выбранных лидов */}
          {selectedLeads.length > 0 && (
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAssignModal}
                style={{ marginRight: '8px' }}
              >
                Назначить на
              </Button>
            </motion.div>
          )}

          {/* Кнопка добавления нового лида */}
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsAddModalOpen(true)}
              style={{ marginRight: '8px' }}
            >
              Добавить Лид
            </Button>
          </motion.div>

          {/* Кнопка импорта лидов */}
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsImportModalOpen(true)}
              style={{ marginRight: '8px' }}
            >
              Импортировать Лиды
            </Button>
          </motion.div>

          {/* Кнопка открытия модального окна фильтра */}
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              variant="outlined"
              onClick={() => setIsFilterModalOpen(true)}
              startIcon={<FilterListIcon />}
              style={{ marginRight: '8px' }}
            >
              Фильтр
            </Button>
          </motion.div>

          {/* Кнопка для выбора отображаемых столбцов */}
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              variant="outlined"
              onClick={handleOpenColumnsPopover}
              startIcon={<ViewColumnIcon />}
            >
              Столбцы
            </Button>
          </motion.div>

          {/* Popover для выбора отображаемых столбцов */}
          <Popover
            id={idColumnsPopover}
            open={openColumnsPopover}
            anchorEl={anchorEl}
            onClose={handleCloseColumnsPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Выберите столбцы
              </Typography>
              <FormGroup>
                {availableColumns.map((column) => (
                  <FormControlLabel
                    key={column.id}
                    control={
                      <Checkbox
                        checked={visibleColumns[column.id]}
                        onChange={() => handleToggleColumn(column.id)}
                      />
                    }
                    label={column.label}
                  />
                ))}
              </FormGroup>
            </Box>
          </Popover>
        </Box>
      </Box>

      {/* Таблица лидов */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* Столбец для выбора лидов */}
              {visibleColumns.actions && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAllLeads}
                    inputProps={{ 'aria-label': 'select all leads' }}
                  />
                </TableCell>
              )}

              {/* Отображаемые столбцы */}
              {availableColumns.map((column) =>
                visibleColumns[column.id] ? (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ) : null
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLeads.length > 0 ? (
              currentLeads.map((lead) => (
                <TableRow key={lead.id} hover>
                  {/* Столбец для выбора лидов */}
                  {visibleColumns.actions && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedLeads.includes(lead.id)}
                        onChange={() => handleSelectLead(lead.id)}
                        inputProps={{ 'aria-labelledby': `lead-checkbox-${lead.id}` }}
                      />
                    </TableCell>
                  )}

                  {/* Столбец ID с кнопкой для перехода на детали */}
                  {visibleColumns.id && (
                    <TableCell>
                      <Button
                        variant="text"
                        onClick={() => handleRedirectToDetails(lead.id)}
                        style={{ textTransform: 'none' }}
                      >
                        {lead.id}
                      </Button>
                    </TableCell>
                  )}

                  {/* Остальные столбцы */}
                  {visibleColumns.phone_number && <TableCell>{lead.phone_number}</TableCell>}
                  {visibleColumns.email && <TableCell>{lead.email}</TableCell>}
                  {visibleColumns.surname && <TableCell>{lead.surname}</TableCell>}
                  {visibleColumns.first_name && <TableCell>{lead.first_name}</TableCell>}
                  {visibleColumns.patronymic && <TableCell>{lead.patronymic || '—'}</TableCell>}
                  {visibleColumns.country && <TableCell>{lead.country}</TableCell>}

                  {/* Столбец комментариев с обрезкой и Tooltip */}
                  {visibleColumns.last_comment && (
                    <TableCell>
                      {lead.last_comment ? (
                        lead.last_comment.length > 100 ? (
                          <Tooltip title={lead.last_comment} arrow>
                            <span style={{ cursor: 'pointer', display: 'inline-block', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {truncateText(lead.last_comment, 100)}
                            </span>
                          </Tooltip>
                        ) : (
                          lead.last_comment
                        )
                      ) : (
                        '—'
                      )}
                    </TableCell>
                  )}

                  {visibleColumns.assignedTo && <TableCell>{lead.assignedTo?.username || 'Не назначен'}</TableCell>}

                  {/* Отображаем имя аффилиата на основе affiliate_id */}
                  {visibleColumns.affiliate && (
                    <TableCell>
                      {lead.affiliate_id
                        ? affiliates.find((aff) => aff.id === lead.affiliate_id)?.name || 'Не указан'
                        : '—'}
                    </TableCell>
                  )}

                  {visibleColumns.office && <TableCell>{lead.office?.name || 'Не указан'}</TableCell>}
                  {visibleColumns.created_at && <TableCell>{new Date(lead.created_at).toLocaleString()}</TableCell>}
                  {visibleColumns.updated_at && <TableCell>{new Date(lead.updated_at).toLocaleString()}</TableCell>}
                  {visibleColumns.status && <TableCell>{lead.status?.name || 'Не установлен'}</TableCell>}
                  {visibleColumns.team && <TableCell>{lead.team?.name || 'Не указана'}</TableCell>}

                  {/* Столбец действий: Подробнее, удалить, заблокировать */}
                  {visibleColumns.actions && (
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleRedirectToDetails(lead.id)}
                        style={{ marginRight: '8px' }}
                      >
                        Подробнее
                      </Button>
                      {(hasRole(ROLES.SUPERADMIN) || hasRole(ROLES.ADMIN) || hasRole(ROLES.TEAMLEAD)) && (
                        <>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteLead(lead.id)}
                            aria-label="удалить"
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            color="warning"
                            onClick={() => handleBlockLead(lead.id)}
                            aria-label="заблокировать"
                          >
                            <BlockIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={visibleColumns.actions ? availableColumns.length + 1 : availableColumns.length} align="center">
                  Нет лидов, соответствующих критериям поиска.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Компоненты Пагинации и Выбора Количества Лидов */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <FormControl variant="outlined" size="small">
          <InputLabel id="leads-per-page-label">Лидов на странице</InputLabel>
          <Select
            labelId="leads-per-page-label"
            value={leadsPerPage}
            onChange={(e) => {
              setLeadsPerPage(e.target.value);
              setCurrentPage(1); // Сбросить страницу при изменении количества лидов
            }}
            label="Лидов на странице"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Box>

      {/* Модальные окна */}
      {isAddModalOpen && <AddLeadModal onClose={() => setIsAddModalOpen(false)} onAddLead={handleAddLead} />}
      {isImportModalOpen && <ImportLeadsModal onClose={() => setIsImportModalOpen(false)} />}
      {isFilterModalOpen && (
        <FilterModal
          open={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          onApplyFilters={(filters) => {
            setAppliedFilters(filters);
            setCurrentPage(1); // Сбросить страницу при применении фильтров
          }}
        />
      )}
      {isAssignModalOpen && (
        <Dialog open={isAssignModalOpen} onClose={() => setIsAssignModalOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>Назначить выбранные лиды</DialogTitle>
          <DialogContent>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box mb={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="team-select-label">Команда</InputLabel>
                    <Select
                      labelId="team-select-label"
                      value={selectedTeam}
                      onChange={handleTeamChange}
                      label="Команда"
                    >
                      {teams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                          {team.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormControl fullWidth variant="outlined" disabled={!selectedTeam || users.length === 0}>
                    <InputLabel id="user-select-label">Пользователь</InputLabel>
                    <Select
                      labelId="user-select-label"
                      value={selectedUser}
                      onChange={handleUserChange}
                      label="Пользователь"
                    >
                      {users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {selectedTeam && users.length === 0 && (
                  <Alert severity="info">В выбранной команде нет пользователей с ролями sale или retention.</Alert>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsAssignModalOpen(false)} color="secondary">
              Отмена
            </Button>
            <Button
              onClick={handleAssignLeads}
              color="primary"
              variant="contained"
              disabled={selectedLeads.length === 0 || !selectedUser}
            >
              Назначить
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertSnackbar onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </Box>
  );
};

export default Leads;
