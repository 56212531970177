// src/components/ToDoListWidget.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Button,
  IconButton,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../utils/axiosInstance';
import { motion } from 'framer-motion';
import './ToDoListWidget.css'; // Для дополнительной стилизации

const ToDoListWidget = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');

  // Получение задач при загрузке компонента
  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axiosInstance.get('/todos'); // Предполагаемый API эндпоинт
      setTasks(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке задач:', error);
    }
  };

  const handleAddTask = async () => {
    if (newTask.trim() === '') return;

    try {
      const response = await axiosInstance.post('/todos', { title: newTask });
      setTasks([...tasks, response.data]);
      setNewTask('');
    } catch (error) {
      console.error('Ошибка при добавлении задачи:', error);
    }
  };

  const handleToggleTask = async (taskId) => {
    const task = tasks.find((t) => t.id === taskId);
    if (!task) return;

    try {
      const response = await axiosInstance.patch(`/todos/${taskId}`, { completed: !task.completed });
      setTasks(tasks.map((t) => (t.id === taskId ? response.data : t)));
    } catch (error) {
      console.error('Ошибка при обновлении задачи:', error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await axiosInstance.delete(`/todos/${taskId}`);
      setTasks(tasks.filter((t) => t.id !== taskId));
    } catch (error) {
      console.error('Ошибка при удалении задачи:', error);
    }
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Paper
        elevation={3}
        sx={{
          bgcolor: '#fff3e0',
          p: 2,
          borderRadius: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" gutterBottom>
          ✅ To-Do List на неделю
        </Typography>
        <Box display="flex" mb={2} alignItems="center">
          <TextField
            label="Новая задача"
            variant="outlined"
            size="small"
            fullWidth
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddTask();
              }
            }}
            sx={{ mr: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTask}
            disabled={newTask.trim() === ''}
            sx={{ height: '40px' }}
          >
            Добавить
          </Button>
        </Box>
        <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <motion.div key={task.id} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTask(task.id)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <Checkbox
                    checked={task.completed}
                    onChange={() => handleToggleTask(task.id)}
                    color="primary"
                  />
                  <ListItemText
                    primary={task.title}
                    sx={{
                      textDecoration: task.completed ? 'line-through' : 'none',
                      color: task.completed ? 'text.disabled' : 'text.primary',
                    }}
                  />
                </ListItem>
              </motion.div>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              Нет задач на текущую неделю.
            </Typography>
          )}
        </List>
      </Paper>
    </motion.div>
  );
};

export default ToDoListWidget;
