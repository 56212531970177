// src/pages/BusinessUnits/Affiliates.js

import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Snackbar,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddAffiliateModal from '../../components/AddAffiliateModal';
import EditAffiliateModal from '../../components/EditAffiliateModal';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import ViewAffiliateModal from '../../components/ViewAffiliateModal'; // Импортируем модальный компонент
import GetAppIcon from '@mui/icons-material/GetApp'; // Импортируем иконку для кнопки

const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Affiliates = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // Состояние для модального окна просмотра
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const fetchAffiliates = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/affiliates');
        console.log('Response data:', response.data); // Для отладки

        // Корректное извлечение массива аффилиатов из ответа
        if (Array.isArray(response.data.data)) {
          setAffiliates(response.data.data);
        } else {
          setAffiliates([]);
          console.warn('Непредвиденная структура данных для аффилиатов:', response.data);
        }
      } catch (error) {
        console.error('Ошибка при загрузке аффилиатов:', error);
        setError('Ошибка при загрузке аффилиатов. Попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliates();
  }, []);

  const handleAddAffiliate = async (affiliateData) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/affiliates', affiliateData);
      // Предполагается, что сервер возвращает объект { affiliate: {...} }
      setAffiliates((prev) => [...prev, response.data.affiliate]);
      setIsAddModalOpen(false);
      setSnackbar({
        open: true,
        message: 'Аффилиат успешно добавлен.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Ошибка при добавлении аффилиата:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при добавлении аффилиата. Попробуйте позже.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditAffiliate = async (updatedAffiliate) => {
    try {
      setLoading(true);
      const response = await axiosInstance.put(`/affiliates/${updatedAffiliate.id}`, updatedAffiliate);
      // Предполагается, что сервер возвращает объект { affiliate: {...} }
      setAffiliates((prev) =>
        prev.map((affiliate) =>
          affiliate.id === updatedAffiliate.id ? response.data.affiliate : affiliate
        )
      );
      setIsEditModalOpen(false);
      setSnackbar({
        open: true,
        message: 'Аффилиат успешно обновлён.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Ошибка при обновлении аффилиата:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при обновлении аффилиата. Попробуйте позже.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAffiliate = async (affiliateId) => {
    if (!window.confirm('Вы уверены, что хотите удалить этого аффилиата?')) return;

    try {
      await axiosInstance.delete(`/affiliates/${affiliateId}`);
      setAffiliates((prev) => prev.filter((affiliate) => affiliate.id !== affiliateId));
      setSnackbar({
        open: true,
        message: 'Аффилиат успешно удалён.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Ошибка при удалении аффилиата:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при удалении аффилиата. Попробуйте позже.',
        severity: 'error',
      });
    }
  };

  const handleBlockAffiliate = async (affiliateId) => {
    if (!window.confirm('Вы уверены, что хотите заблокировать этого аффилиата?')) return;

    try {
      await axiosInstance.patch(`/affiliates/${affiliateId}/block`);
      setAffiliates((prev) =>
        prev.map((affiliate) =>
          affiliate.id === affiliateId ? { ...affiliate, blocked: true } : affiliate
        )
      );
      setSnackbar({
        open: true,
        message: 'Аффилиат успешно заблокирован.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Ошибка при блокировке аффилиата:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при блокировке аффилиата. Попробуйте позже.',
        severity: 'error',
      });
    }
  };

  const handleOpenEdit = (affiliate) => {
    setSelectedAffiliate(affiliate);
    setIsEditModalOpen(true);
  };

  const handleOpenView = (affiliate) => {
    setSelectedAffiliate(affiliate);
    setIsViewModalOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleViewDetails = (affiliateId) => {
    navigate(`/business-units/affiliates/${affiliateId}`); // Исправленный путь
  };

  // Функция для обновления API ключа после регенерации
  const refreshAffiliate = (affiliateId, newApiKey) => {
    setAffiliates((prev) =>
      prev.map((affiliate) =>
        affiliate.id === affiliateId ? { ...affiliate, api_key: newApiKey } : affiliate
      )
    );
    // Также обновляем `selectedAffiliate`, если он открыт
    if (selectedAffiliate && selectedAffiliate.id === affiliateId) {
      setSelectedAffiliate((prev) => ({ ...prev, api_key: newApiKey }));
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Аффилиаты
      </Typography>

      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddModalOpen(true)}
          style={{ marginRight: '8px' }}
        >
          Добавить Аффилиата
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          href="/APIDOC.pdf" // Указываем правильный путь к документу
          download="APIDOC.pdf" // Имя файла при скачивании
          startIcon={<GetAppIcon />} // Добавляем иконку к кнопке
        >
          Скачать Документацию API
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Офис</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {affiliates.map((affiliate) => (
              <TableRow key={affiliate.id} hover>
                <TableCell>{affiliate.id}</TableCell>
                <TableCell>{affiliate.username}</TableCell>
                <TableCell>{affiliate.email}</TableCell>
                <TableCell>{affiliate.office?.name || 'Не указан'}</TableCell>
                <TableCell>{affiliate.blocked ? 'Заблокирован' : 'Активен'}</TableCell>
                <TableCell>
                  <Tooltip title="Просмотр">
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenView(affiliate)}
                      aria-label="просмотр"
                      style={{ marginRight: '8px' }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  {(auth.user?.userRole?.name === 'superadmin' || auth.user?.userRole?.name === 'admin') && (
                    <>
                      <Tooltip title="Удалить">
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteAffiliate(affiliate.id)}
                          aria-label="удалить"
                          style={{ marginRight: '8px' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Заблокировать">
                        <IconButton
                          color="warning"
                          onClick={() => handleBlockAffiliate(affiliate.id)}
                          aria-label="заблокировать"
                          style={{ marginRight: '8px' }}
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Редактировать">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleOpenEdit(affiliate)}
                          style={{ marginLeft: '8px' }}
                        >
                          Редактировать
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {affiliates.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Нет аффилиатов для отображения.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Модальные окна */}
      {isAddModalOpen && (
        <AddAffiliateModal
          open={isAddModalOpen}
          handleClose={() => setIsAddModalOpen(false)}
          handleAddAffiliate={handleAddAffiliate}
        />
      )}
      {isEditModalOpen && selectedAffiliate && (
        <EditAffiliateModal
          affiliate={selectedAffiliate}
          onClose={() => setIsEditModalOpen(false)}
          handleEditAffiliate={handleEditAffiliate}
        />
      )}
      {isViewModalOpen && selectedAffiliate && (
        <ViewAffiliateModal
          open={isViewModalOpen}
          handleClose={() => setIsViewModalOpen(false)}
          affiliate={selectedAffiliate}
          refreshAffiliate={refreshAffiliate}
        />
      )}

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertSnackbar onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </Box>
  );
};

export default Affiliates;
