import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import axiosInstance from '../utils/axiosInstance';

const EditOfficePage = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [office, setOffice] = useState(state?.office || null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    // Если офиса нет в state, грузим его с API
    if (!office) {
      axiosInstance.get(`/offices/${id}`).then((response) => {
        setOffice(response.data);
        setName(response.data.name || '');
        setDescription(response.data.description || '');
      });
    } else {
      // Если офис есть в state, сразу выставляем значения
      setName(office.name || '');
      setDescription(office.description || '');
    }
  }, [office, id]);

  const handleSave = async () => {
    try {
      await axiosInstance.put(`/offices/${id}`, { name, description });
      navigate('/offices'); // Возвращаемся на страницу офисов
    } catch (error) {
      console.error('Ошибка сохранения:', error);
    }
  };

  if (!office) {
    return <Typography>Загрузка офиса...</Typography>;
  }

  return (
    <Box p={3}>
      <Typography variant="h4">Редактировать офис</Typography>
      <TextField
        margin="dense"
        label="Название"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        margin="dense"
        label="Описание"
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Box mt={2}>
        <Button variant="contained" onClick={handleSave}>
          Сохранить
        </Button>
        <Button variant="outlined" onClick={() => navigate('/offices')} style={{ marginLeft: '8px' }}>
          Отмена
        </Button>
      </Box>
    </Box>
  );
};

export default EditOfficePage;
