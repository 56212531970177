// src/pages/Chats/ChatList.js
import React from 'react';
import './ChatList.css';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const ChatList = ({ chats, onSelect, selectedChat, onTogglePin, onDeleteChat }) => {
  const pinnedChats = chats.filter(chat => chat.isPinned);
  const otherChats = chats.filter(chat => !chat.isPinned);

  const renderChats = (chatArray) => {
    return chatArray.map(chat => {
      const hasMessages = Array.isArray(chat.messages) && chat.messages.length > 0;
      const lastMessage = hasMessages ? chat.messages[chat.messages.length - 1] : null;
      const lastMessageText = hasMessages
        ? `${lastMessage.sender?.username || 'Пользователь'}: ${lastMessage.content}`
        : 'Нет сообщений';

      return (
        <div
          key={chat.id}
          className={`chat-item ${selectedChat && selectedChat.id === chat.id ? 'selected' : ''}`}
          onClick={() => onSelect(chat)}
        >
          <div className="chat-header-row">
            <div className="chat-name">{chat.name || 'Чат'}</div>
            <div className="chat-actions">
              <button
                className="pin-button"
                onClick={(e) => {
                  e.stopPropagation();
                  onTogglePin(chat);
                }}
              >
                {chat.isPinned ? 'Открепить' : 'Закрепить'}
              </button>
              <IconButton
                className="delete-button"
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteChat(chat);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
          <div className="last-message">{lastMessageText}</div>
        </div>
      );
    });
  };

  return (
    <div className="chat-list">
      {pinnedChats.length > 0 && (
        <div className="pinned-section">
          <h3 className="section-title">Закреплённые чаты</h3>
          {renderChats(pinnedChats)}
        </div>
      )}
      {otherChats.length > 0 && (
        <div className="other-chats-section">
          {pinnedChats.length > 0 && <h3 className="section-title">Другие чаты</h3>}
          {renderChats(otherChats)}
        </div>
      )}
      {pinnedChats.length === 0 && otherChats.length === 0 && (
        <div className="no-chats">Нет чатов для отображения.</div>
      )}
    </div>
  );
};

export default ChatList;
