// src/pages/CreateLead.js
import React, { useState } from 'react';
import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';

const CreateLead = () => {
  const [name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/leads', { name, contactInfo });
      navigate('/leads');
    } catch (error) {
      console.error('Ошибка при создании лида:', error);
    }
  };

  return (
    <div>
      <h2>Создать Лид</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Имя:
          <input 
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
        </label>
        <br />
        <label>
          Контактная Информация:
          <input 
            type="text" 
            value={contactInfo} 
            onChange={(e) => setContactInfo(e.target.value)} 
            required 
          />
        </label>
        <br />
        <button type="submit">Создать</button>
      </form>
    </div>
  );
};

export default CreateLead;
