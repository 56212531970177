// src/pages/ChatPage.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, TextField, IconButton, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'; // Используйте из '@mui/material/styles'
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './AiChat.css';
import EmojiPicker from 'emoji-picker-react';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ReactLoading from 'react-loading';
import Markdown from 'markdown-to-jsx';

const MessageContainer = styled(Box)(({ theme, isUser }) => ({
  display: 'flex',
  justifyContent: isUser ? 'flex-end' : 'flex-start',
  marginBottom: theme.spacing(1),
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  backgroundColor: isUser ? theme.palette.primary.main : theme.palette.secondary.main,
  color: theme.palette.getContrastText(
    isUser ? theme.palette.primary.main : theme.palette.secondary.main
  ),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  maxWidth: '60%',
  wordWrap: 'break-word',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '80%',
  },
}));

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isAITyping, setIsAITyping] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);

  // Загрузка сообщений из localStorage при загрузке компонента
  useEffect(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  // Сохранение сообщений в localStorage при их изменении
  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isAITyping]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    const timestamp = new Date().toLocaleTimeString();

    // Добавляем сообщение пользователя
    const userMessage = { sender: 'user', content: input, timestamp };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setIsAITyping(true);
    setShowEmojiPicker(false);

    try {
      const response = await axios.post('http://65.108.233.13:5000/api/ai-chat/chat', {
        prompt: input,
      });

      const aiMessage = {
        sender: 'ai',
        content: response.data.message,
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error('Ошибка при обращении к AI-чату:', error);
      const errorMessage = {
        sender: 'ai',
        content: 'Извините, произошла ошибка при обработке вашего запроса.',
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsAITyping(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setInput((prev) => prev + emojiObject.emoji);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          marginBottom: 2,
        }}
      >
        <TransitionGroup>
          {messages.map((message, index) => (
            <CSSTransition key={index} timeout={500} classNames="message">
              <MessageContainer isUser={message.sender === 'user'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    sx={{ bgcolor: message.sender === 'user' ? 'primary.main' : 'secondary.main' }}
                  >
                    {message.sender === 'user' ? <PersonIcon /> : <SmartToyIcon />}
                  </Avatar>
                  <MessageBubble isUser={message.sender === 'user'}>
                    <Markdown>{message.content}</Markdown>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'block',
                        textAlign: message.sender === 'user' ? 'right' : 'left',
                      }}
                    >
                      {message.timestamp}
                    </Typography>
                  </MessageBubble>
                </Box>
              </MessageContainer>
            </CSSTransition>
          ))}
          {isAITyping && (
            <MessageContainer isUser={false}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: 'secondary.main' }}>
                  <SmartToyIcon />
                </Avatar>
                <MessageBubble isUser={false}>
                  <ReactLoading type="bars" color="#fff" height={30} width={50} />
                </MessageBubble>
              </Box>
            </MessageContainer>
          )}
        </TransitionGroup>
        <div ref={messagesEndRef} />
      </Box>
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => setShowEmojiPicker((val) => !val)}>
          <SentimentSatisfiedAltIcon />
        </IconButton>
        {showEmojiPicker && (
          <Box sx={{ position: 'absolute', bottom: '60px', left: '0', zIndex: 1 }}>
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </Box>
        )}
        <TextField
          variant="outlined"
          placeholder="Введите сообщение..."
          fullWidth
          multiline
          maxRows={4}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          sx={{ marginRight: 1 }}
        />
        <IconButton color="primary" onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatPage;
