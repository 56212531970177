// src/pages/Dashboard/Dashboard.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Alert,
  Link as MuiLink,
  Snackbar,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HistoryIcon from '@mui/icons-material/History';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import './Dashboard.css';

import WidgetSelectorModal from '../../components/WidgetSelectorModal';
import ClockWidget from '../../components/ClockWidget';
import ToDoListWidget from '../../components/ToDoListWidget';
import CalendarWidget from '../../components/CalendarWidget';

import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import MuiAlertComponent from '@mui/material/Alert';

const Dashboard = () => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const [recentLeads, setRecentLeads] = useState([]);
  const [leadActions, setLeadActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [activeWidgets, setActiveWidgets] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [layout, setLayout] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(false);

        const [leadsResponse, actionsResponse, widgetsResponse] = await Promise.all([
          axiosInstance.get('/leads/recent'),
          axiosInstance.get('/leads/latest-actions'),
          axiosInstance.get('/widgets/user/widgets'),
        ]);

        const leadsData = Array.isArray(leadsResponse.data.leads) ? leadsResponse.data.leads : [];
        const actionsData = Array.isArray(actionsResponse.data) ? actionsResponse.data : [];
        const widgetsData = Array.isArray(widgetsResponse.data) ? widgetsResponse.data : [];

        setRecentLeads(leadsData);
        setLeadActions(actionsData);
        setActiveWidgets(widgetsData);

        // Инициализация layout на основе активных виджетов
        const initialLayout = widgetsData.map((widget, index) => ({
          i: widget,
          x: (index % 3) * 4,
          y: Infinity, // Размещаем новые виджеты внизу
          w: 4,
          h: 4,
        }));
        setLayout(initialLayout);
      } catch (err) {
        console.error('Ошибка загрузки данных дашборда:', err);
        if (err.response && err.response.status === 401) {
          logout();
          navigate('/login');
        } else {
          setError(true);
        }
      } finally {
        setLoading(false);
      }
    };

    if (auth.token) {
      fetchDashboardData();
    } else {
      navigate('/login');
    }
  }, [auth, navigate, logout]);

  const handleRefreshDashboard = async () => {
    try {
      const widgetsResponse = await axiosInstance.get('/widgets/user/widgets');
      const widgetsData = Array.isArray(widgetsResponse.data) ? widgetsResponse.data : [];
      setActiveWidgets(widgetsData);

      // Обновление layout на основе новых виджетов
      const updatedLayout = widgetsData.map((widget, index) => ({
        i: widget,
        x: (index % 3) * 4,
        y: Infinity, // Новые виджеты добавляются внизу
        w: 4,
        h: 4,
      }));
      setLayout(updatedLayout);
    } catch (err) {
      console.error('Ошибка при обновлении виджетов:', err);
    }
  };

  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
    // Здесь можно сохранить layout на бэкенд, если необходимо
  };

  const renderWidget = (widget) => {
    switch (widget) {
      case 'Clock':
        return <ClockWidget />;
      case 'ToDoList':
        return <ToDoListWidget />;
      case 'Calendar':
        return <CalendarWidget />;
      default:
        return null;
    }
  };

  return (
    <Box className="dashboard-container">
      <Typography variant="h4" gutterBottom className="dashboard-title">
        Панель управления
      </Typography>
      <Button
        variant="outlined"
        onClick={() => setModalOpen(true)}
        className="select-widgets-button"
      >
        Выбрать Виджеты
      </Button>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box mt={4}>
          <Alert severity="error" className="dashboard-alert">
            Ошибка загрузки данных. Попробуйте позже.
          </Alert>
        </Box>
      ) : (
        <>
          {/* Статические карточки */}
          <Box className="static-cards-container">
            {/* Карточка "Последние лиды" */}
            <Box className="dashboard-card static-card">
              <Typography variant="h6" gutterBottom className="card-header">
                <PersonAddIcon fontSize="large" className="icon-primary" /> Последние лиды
              </Typography>
              <Box className="static-content">
                {recentLeads.length > 0 ? (
                  recentLeads.slice(0, 10).map((lead) => (
                    <Typography key={lead.id} variant="body2" className="dashboard-item">
                      <strong>
                        <MuiLink
                          component={RouterLink}
                          to={`/leads/${lead.id}`}
                          underline="hover"
                          color="primary"
                        >
                          Лид {lead.id}
                        </MuiLink>
                        :
                      </strong>{' '}
                      {lead.first_name} {lead.surname} <span className="separator">_</span> {lead.phone_number} <span className="separator">_</span> {new Date(lead.created_at).toLocaleDateString()}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body2" className="dashboard-placeholder">
                    Нет доступных лидов
                  </Typography>
                )}
              </Box>
            </Box>

            {/* Карточка "Последние действия" */}
            <Box className="dashboard-card static-card">
              <Typography variant="h6" gutterBottom className="card-header">
                <HistoryIcon fontSize="large" className="icon-success" /> Последние действия
              </Typography>
              <Box className="static-content">
                {leadActions.length > 0 ? (
                  leadActions.slice(0, 10).map((action) => (
                    <Typography key={action.id} variant="body2" className="dashboard-item">
                      <strong>
                        <MuiLink
                          component={RouterLink}
                          to={`/leads/${action.lead_id}`}
                          underline="hover"
                          color="primary"
                        >
                          Лид {action.lead_id}
                        </MuiLink>
                        :
                      </strong>{' '}
                      {action.description} пользователем {action.updated_by} (<span className="separator">{new Date(action.created_at).toLocaleTimeString()}</span>)
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body2" className="dashboard-placeholder">
                    Нет действий с лидами
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          {/* Динамические виджеты */}
          <GridLayout
            className="layout"
            layout={layout}
            cols={12}
            rowHeight={30}
            width={1200}
            onLayoutChange={onLayoutChange}
            draggableHandle=".card-header"
            resizeHandles={['se', 'sw', 'ne', 'nw']}
            isResizable={true}
            isDraggable={true}
            margin={[10, 10]}
          >
            {activeWidgets.map((widget) => (
              <Box key={widget} className="grid-item">
                <Box className="dashboard-card">
                  {renderWidget(widget)}
                </Box>
              </Box>
            ))}
          </GridLayout>
        </>
      )}

      <WidgetSelectorModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        refreshDashboard={handleRefreshDashboard}
      />

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={false} // Измените по необходимости
        autoHideDuration={6000}
        onClose={() => {}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlertComponent onClose={() => {}} severity="success" sx={{ width: '100%' }}>
          Виджеты обновлены
        </MuiAlertComponent>
      </Snackbar>
    </Box>
  );
};

export default Dashboard;
