// src/components/AddOfficeModal.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../utils/axiosConfig';

const AddOfficeModal = ({ open, onClose, onOfficeAdded }) => {
  const { auth } = useAuth(); 
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setName(e.target.value);
    if (e.target.value) {
      setError('');
    }
  };

  const validate = () => {
    if (!name.trim()) {
      setError('Название офиса обязательно');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение формы
    if (validate()) {
      try {
        const response = await axiosInstance.post(
          '/offices', // Корректный эндпоинт для создания офиса
          {
            name,
            office_id: auth.user.office_id, // Передаём office_id
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        if (response.data && response.data.office) {
          onOfficeAdded(response.data.office); // Обновляем список офисов в родительском компоненте
          setName('');
          onClose();
        } else {
          setError('Не удалось создать офис. Попробуйте позже.');
        }
      } catch (error) {
        console.error('Ошибка создания офиса:', error);
        // Проверяем, есть ли сообщение об ошибке от сервера
        if (error.response && error.response.data && error.response.data.errors) {
          setError(error.response.data.errors[0].msg);
        } else {
          setError('Не удалось создать офис. Попробуйте позже.');
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Создать новый офис
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Название офиса"
                fullWidth
                value={name}
                onChange={handleChange}
                error={!!error}
                helperText={error}
                autoFocus
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Отмена
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Создать офис
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOfficeModal;
