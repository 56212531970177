// frontend/src/pages/Chats/MessageInput.js

import React, { useState } from 'react';
import './MessageInput.css';

const MessageInput = ({ onSend }) => {
  const [content, setContent] = useState('');

  const handleSend = () => {
    if (content.trim() === '') return;
    onSend(content.trim());
    setContent('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="message-input">
      <textarea
        value={content}
        onChange={e => setContent(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Введите сообщение..."
      />
      <button onClick={handleSend}>Отправить</button>
    </div>
  );
};

export default MessageInput;
