// src/pages/Accounts/TeamDetails.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import AddUsersToTeamModal from '../../components/AddUsersToTeamModal';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from '../../utils/axiosInstance';

const TeamDetails = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [team, setTeam] = useState(null);
  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchTeam = async () => {
      try {
        const response = await axios.get(`/business-units/teams/${teamId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTeam(response.data.team);
      } catch (error) {
        console.error('Ошибка загрузки команды:', error);
        setError(true);
      }
    };

    fetchTeam();
  }, [token, teamId, navigate]);

  const handleOpenAddUsersModal = () => setIsAddUsersModalOpen(true);
  const handleCloseAddUsersModal = () => setIsAddUsersModalOpen(false);

  const handleAddUsers = async (selectedUserIds) => {
    try {
      await axios.post(
        `/business-units/teams/${teamId}/users`,
        { userIds: selectedUserIds },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Обновляем данные команды
      const response = await axios.get(`/business-units/teams/${teamId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTeam(response.data.team);
    } catch (error) {
      console.error('Ошибка добавления пользователей в команду:', error);
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      await axios.delete(`/business-units/teams/${teamId}/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Обновляем данные команды
      const response = await axios.get(`/business-units/teams/${teamId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTeam(response.data.team);
    } catch (error) {
      console.error('Ошибка удаления пользователя из команды:', error);
    }
  };

  if (error) {
    return (
      <Box p={3}>
        <Typography variant="h6">Ошибка загрузки команды.</Typography>
      </Box>
    );
  }

  if (!team) {
    return (
      <Box p={3}>
        <Typography>Загрузка...</Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {team.name}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Лидер команды: {team.leader}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {team.description}
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOpenAddUsersModal}
      >
        Добавить пользователей
      </Button>

      <Typography variant="h6" sx={{ mt: 3 }}>
        Участники команды
      </Typography>
      <List>
        {team.users.map((user) => (
          <ListItem
            key={user.id}
            secondaryAction={
              <IconButton edge="end" onClick={() => handleRemoveUser(user.id)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={user.username} secondary={user.email} />
          </ListItem>
        ))}
      </List>

      {/* Модальное окно для добавления пользователей в команду */}
      <AddUsersToTeamModal
        open={isAddUsersModalOpen}
        handleClose={handleCloseAddUsersModal}
        handleAddUsers={handleAddUsers}
        existingUsers={team.users}
      />
    </Box>
  );
};

export default TeamDetails;
