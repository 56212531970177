// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [auth, setAuth] = useState({
    token: localStorage.getItem('token'),
    user: null,
    roles: [],
    loading: true,
  });

  const logout = () => {
    console.log('Logging out...');
    localStorage.removeItem('token');
    delete axiosInstance.defaults.headers.common['Authorization'];
    setAuth({
      token: null,
      user: null,
      roles: [],
      loading: false,
    });
    navigate('/login');
  };

  const fetchUserAndRoles = async (token) => {
    try {
      console.log('Fetching user and roles with token:', token);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const userResponse = await axiosInstance.get('/auth/me');
      console.log('User response data:', userResponse.data);

      const user = userResponse.data.user;
      console.log('User fetched:', user);

      // Здесь мы гарантированно получаем массив строк из роли
      // Если user.role = { name: "superadmin" }, то roles = ["superadmin"]
      // Если у пользователя нет роли, roles будет пустым массивом
      const roles = user.role && user.role.name 
        ? [user.role.name.toLowerCase()] 
        : [];

      console.log('Roles fetched:', roles);

      const updatedUser = { ...user };

      setAuth({
        token,
        user: updatedUser,
        roles,
        loading: false,
      });
    } catch (error) {
      console.error('Error fetching user or roles:', error);
      logout();
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      if (auth.token) {
        await fetchUserAndRoles(auth.token);
      } else {
        setAuth((prev) => ({ ...prev, loading: false }));
      }
    };

    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  const login = async (email, password) => {
    try {
      console.log('Attempting to login with:', email);
      const response = await axiosInstance.post('/auth/login', { email, password });
      const { token } = response.data;
      console.log('Login successful, token:', token);

      localStorage.setItem('token', token);
      setAuth((prev) => ({ ...prev, token }));

      await fetchUserAndRoles(token);
      navigate('/dashboard');

      return { success: true };
    } catch (error) {
      console.error('Login error:', error);
      const message = error.response?.data?.message || 'Ошибка при входе';
      return { success: false, message };
    }
  };

  const hasRole = (role) => auth.roles.includes(role.toLowerCase());

  return (
    <AuthContext.Provider value={{ auth, login, logout, hasRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
