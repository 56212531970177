// frontend/src/components/Sidebar/Sidebar.js

import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Divider,
  Box,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  Settings as SettingsIcon, // Импортируем иконку настроек
  People as PeopleIcon,
  Group as GroupIcon,
  Business as BusinessIcon,
  SupportAgent as SupportAgentIcon,
  Chat as ChatIcon,
  Message as MessageIcon,
  Logout as LogoutIcon,
  ListAlt as ListAltIcon, // Иконка для лидов от аффилиатов
} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const drawerWidth = 240;

const Sidebar = () => {
  const { hasRole } = useAuth();

  // Меню для различных ролей
  const menuItems = [
    {
      text: 'Дашборд',
      icon: <DashboardIcon />,
      path: '/dashboard',
      roles: ['superadmin', 'admin', 'teamlead', 'sale', 'retention'],
    },
    {
      text: 'Лиды',
      icon: <AssignmentIcon />,
      path: '/leads',
      roles: ['superadmin', 'admin', 'teamlead', 'sale', 'retention'],
    },
    {
      text: 'Лиды от Аффилиатов',
      icon: <ListAltIcon />,
      path: '/leads/affiliates',
      roles: ['superadmin', 'admin'],
    },
    {
      text: 'Пользователи',
      icon: <PeopleIcon />,
      path: '/users',
      roles: ['superadmin', 'admin'],
    },
    {
      text: 'Команды',
      icon: <GroupIcon />,
      path: '/business-units/teams',
      roles: ['superadmin', 'admin', 'teamlead'],
    },
    {
      text: 'Аффилиаты',
      icon: <BusinessIcon />,
      path: '/business-units/affiliates',
      roles: ['superadmin', 'admin'],
    },
    {
      text: 'Колл-центры',
      icon: <BusinessIcon />,
      path: '/business-units/call-centers',
      roles: ['superadmin', 'admin'],
    },
    {
      text: 'Поддержка',
      icon: <SupportAgentIcon />,
      path: '/support',
      roles: ['superadmin', 'teamlead'],
    },
    {
      text: 'AI Ассистент',
      icon: <MessageIcon />,
      path: '/ai-chat',
      roles: ['superadmin', 'admin', 'teamlead', 'sale', 'retention'],
    },
    {
      text: 'Чаты',
      icon: <ChatIcon />,
      path: '/chats',
      roles: ['superadmin', 'admin', 'teamlead', 'sale', 'retention'],
    },
    {
      text: 'Настройки', // Добавляем пункт "Настройки"
      icon: <SettingsIcon />,
      path: '/settings',
      roles: ['superadmin', 'admin', 'teamlead'],
    },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Divider />
      <Box display="flex" flexDirection="column" height="100%">
        <List>
          {menuItems
            .filter((item) => item.roles.some((role) => hasRole(role)))
            .map((item) => (
              <ListItem
                button
                key={item.text}
                component={NavLink}
                to={item.path}
                style={({ isActive }) => ({
                  backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                  textDecoration: 'none',
                  color: 'inherit',
                })}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
        </List>
        <Box flexGrow={1} />
        <Divider />
        <List>
          <ListItem
            button
            component={NavLink}
            to="/logout"
            style={({ isActive }) => ({
              backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              textDecoration: 'none',
              color: 'inherit',
            })}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Выйти" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
