// src/pages/Teams/Teams.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Для редиректа
import axiosInstance from '../../utils/axiosInstance';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Snackbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import AddTeamModal from '../../components/AddTeamModal'; // Исправленный путь
import EditTeamModal from '../../components/EditTeamModal';
import MuiAlert from '@mui/material/Alert';

// Компонент для отображения Snackbar с уведомлениями
const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Teams = () => {
  const { auth } = useAuth();
  const navigate = useNavigate(); // Для редиректа
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/teams');
        if (response.data && Array.isArray(response.data)) {
          setTeams(response.data);
        } else {
          console.error('Неверный формат данных от API:', response.data);
          setError('Ошибка при загрузке команд. Неверный формат ответа от сервера.');
        }
      } catch (error) {
        console.error('Ошибка при загрузке команд:', error);
        setError('Ошибка при загрузке команд. Попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  const handleDeleteTeam = async (teamId) => {
    if (!window.confirm('Вы уверены, что хотите удалить эту команду?')) return;

    try {
      await axiosInstance.delete(`/teams/${teamId}`);
      setTeams((prev) => prev.filter((team) => team.id !== teamId));
      setSnackbar({
        open: true,
        message: 'Команда успешно удалена.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Ошибка при удалении команды:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при удалении команды. Попробуйте позже.',
        severity: 'error',
      });
    }
  };

  const handleBlockTeam = async (teamId) => {
    if (!window.confirm('Вы уверены, что хотите заблокировать эту команду?')) return;

    try {
      await axiosInstance.patch(`/teams/${teamId}/block`);
      setTeams((prev) =>
        prev.map((team) =>
          team.id === teamId ? { ...team, blocked: true } : team
        )
      );
      setSnackbar({
        open: true,
        message: 'Команда успешно заблокирована.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Ошибка при блокировке команды:', error);
      setSnackbar({
        open: true,
        message: 'Ошибка при блокировке команды. Попробуйте позже.',
        severity: 'error',
      });
    }
  };

  const handleRedirectToDetails = (teamId) => {
    navigate(`/business-units/teams/${teamId}`);
  };

  const handleOpenEdit = (team) => {
    setSelectedTeam(team);
    setIsEditModalOpen(true);
  };

  const handleAddTeam = (newTeam) => {
    setTeams((prevTeams) => [newTeam, ...prevTeams]);
    setSnackbar({
      open: true,
      message: 'Команда успешно добавлена.',
      severity: 'success',
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Извлекаем officeId из объекта пользователя
  const officeId = auth.user?.office_id;

  // Добавим проверку, что officeId существует
  if (!officeId) {
    console.error('officeId отсутствует у пользователя.');
    return (
      <Box>
        <Alert severity="error">
          Ошибка: не удалось получить информацию об офисе пользователя.
        </Alert>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Команды
      </Typography>

      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddModalOpen(true)}
          style={{ marginRight: '8px' }}
        >
          Добавить Команду
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Название</TableCell>
              {/* Удалено поле "Описание" */}
              <TableCell>Офис</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team) => (
              <TableRow key={team.id} hover>
                {/* Редирект при клике на ID команды */}
                <TableCell>
                  <Button
                    variant="text"
                    onClick={() => handleRedirectToDetails(team.id)}
                  >
                    {team.id}
                  </Button>
                </TableCell>
                <TableCell>{team.name}</TableCell>
                {/* Удалено поле "Описание" */}
                <TableCell>{team.office?.name || 'Не указан'}</TableCell>
                <TableCell>{team.blocked ? 'Заблокирована' : 'Активна'}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleRedirectToDetails(team.id)}
                    style={{ marginRight: '8px' }}
                  >
                    Подробнее
                  </Button>
                  {(auth.user?.userRole?.name === 'superadmin' || auth.user?.userRole?.name === 'admin') && (
                    <>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteTeam(team.id)}
                        aria-label="удалить"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        color="warning"
                        onClick={() => handleBlockTeam(team.id)}
                        aria-label="заблокировать"
                      >
                        <BlockIcon />
                      </IconButton>
                      {/* Кнопка для открытия модального окна редактирования */}
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenEdit(team)}
                        style={{ marginLeft: '8px' }}
                      >
                        Редактировать
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Модальные окна */}
      {isAddModalOpen && (
        <AddTeamModal
          open={isAddModalOpen}
          handleClose={() => setIsAddModalOpen(false)}
          handleAddTeam={handleAddTeam}
          officeId={officeId} // Передаём officeId как пропс
        />
      )}
      {isEditModalOpen && selectedTeam && (
        <EditTeamModal team={selectedTeam} onClose={() => setIsEditModalOpen(false)} />
      )}

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertSnackbar onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </Box>
  );
};

export default Teams;
