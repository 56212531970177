// frontend/src/pages/Settings/Settings.js

import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
} from '@mui/material';
import Statuses from './Statuses';
import SecuritySettings from './SecuritySettings';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box padding={3} maxWidth="1200px" margin="0 auto">
      <Typography variant="h4" gutterBottom>
        Настройки
      </Typography>
      <Paper elevation={3}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Статусы" />
          <Tab label="Безопасность" />
        </Tabs>
        <Box padding={3}>
          {activeTab === 0 && <Statuses />}
          {activeTab === 1 && <SecuritySettings />}
        </Box>
      </Paper>
    </Box>
  );
};

export default Settings;
