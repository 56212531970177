// src/pages/Calendar/Calendar.js
import React, { useState } from 'react';
import './Calendar.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarPage = () => {
  const [date, setDate] = useState(new Date());
  const [reminders, setReminders] = useState([]);

  const onChange = date => {
    setDate(date);
  };

  const addReminder = () => {
    const message = prompt('Введите напоминание:');
    if (message) {
      setReminders([...reminders, { date, message }]);
    }
  };

  return (
    <div className="calendar-container">
      <h2>Календарь</h2>
      <div className="calendar-section">
        <Calendar
          onChange={onChange}
          value={date}
        />
        <button onClick={addReminder} className="add-reminder-button">Добавить Напоминание</button>
      </div>
      <div className="reminders-section">
        <h3>Напоминания на {date.toLocaleDateString()}:</h3>
        {reminders.filter(reminder => 
          reminder.date.toDateString() === date.toDateString()
        ).length > 0 ? (
          <ul>
            {reminders.filter(reminder => 
              reminder.date.toDateString() === date.toDateString()
            ).map((reminder, index) => (
              <li key={index}>{reminder.message}</li>
            ))}
          </ul>
        ) : (
          <p>Нет напоминаний на эту дату.</p>
        )}
      </div>
    </div>
  );
};

export default CalendarPage;
