// frontend/src/pages/Settings/Statuses.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import StatusModal from './StatusModal';
import axiosInstance from '../../utils/axiosConfig'; // Убедитесь, что путь правильный

const Statuses = () => {
  const [statuses, setStatuses] = useState([]);
  const [offices, setOffices] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  // Загрузка статусов и офисов при инициализации
  useEffect(() => {
    fetchStatuses();
    fetchOffices();
  }, []);

  const fetchStatuses = async () => {
    try {
      const response = await axiosInstance.get('/statuses');
      console.log('Ответ от /statuses:', response.data); // Для отладки
      setStatuses(Array.isArray(response.data) ? response.data : []); // Устанавливаем массив напрямую
    } catch (error) {
      console.error('Ошибка при загрузке статусов:', error);
      setStatuses([]); // Обнуляем состояние при ошибке
    }
  };

  const fetchOffices = async () => {
    try {
      const response = await axiosInstance.get('/offices');
      console.log('Ответ от /offices:', response.data); // Для отладки
      setOffices(Array.isArray(response.data) ? response.data : []); // Устанавливаем массив напрямую
    } catch (error) {
      console.error('Ошибка при загрузке офисов:', error);
      setOffices([]); // Обнуляем состояние при ошибке
    }
  };

  const handleAddStatus = () => {
    setModalOpen(true);
  };

  const handleDeleteStatus = async (id) => {
    try {
      await axiosInstance.delete(`/statuses/${id}`);
      setStatuses(statuses.filter((status) => status.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении статуса:', error);
      // Можно добавить уведомление пользователю об ошибке
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleStatusCreated = (newStatus) => {
    setStatuses([...statuses, newStatus]);
    setModalOpen(false);
  };

  // Функция для получения имени офиса по его ID
  const getOfficeName = (officeId) => {
    const office = offices.find((office) => office.id === officeId);
    return office ? office.name : 'Неизвестный офис';
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h6">Управление статусами</Typography>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddStatus}>
          Добавить статус
        </Button>
      </Box>
      <Divider />
      <List>
        {statuses.map((status) => (
          <ListItem key={status.id} divider>
            <ListItemText
              primary={status.name}
              secondary={`Офис: ${getOfficeName(status.office_id)}`} // Используем office_id для получения имени офиса
            />
            <Chip
              label={status.name}
              style={{ backgroundColor: status.color || '#000', color: '#fff', marginRight: '16px' }} // Устанавливаем цвет, если он есть
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteStatus(status.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {statuses.length === 0 && (
          <Typography variant="body2" color="textSecondary" align="center" mt={2}>
            Нет доступных статусов.
          </Typography>
        )}
      </List>
      <StatusModal
        open={modalOpen}
        onClose={handleModalClose}
        onStatusCreated={handleStatusCreated}
        offices={offices}
      />
    </Box>
  );
};

export default Statuses;
