// src/pages/Users/CreateUser.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import axiosInstance from '../../utils/axiosInstance';
import { useAuth } from '../../context/AuthContext';

const CreateUser = ({ open, onClose, onUserCreated }) => {
  const { auth } = useAuth();
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [errorRoles, setErrorRoles] = useState(null);
  const [errorTeams, setErrorTeams] = useState(null);
  const [formData, setFormData] = useState({
    username: '', // Исправлено на username для соответствия серверу
    email: '',
    password: '',
    role_id: '',
    team_id: '',
    office_id: auth.user?.office_id || null, // Офис подтягивается от текущего пользователя
  });
  const [submitting, setSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [successSubmit, setSuccessSubmit] = useState(false);

  // Загружаем роли и команды при открытии модального окна
  useEffect(() => {
    if (!open) return;

    const fetchRoles = async () => {
      setLoadingRoles(true);
      try {
        const response = await axiosInstance.get('/roles');
        setRoles(response.data);
        setErrorRoles(null);
      } catch (error) {
        console.error('Ошибка при загрузке ролей:', error);
        setErrorRoles('Не удалось загрузить роли.');
      } finally {
        setLoadingRoles(false);
      }
    };

    const fetchTeams = async () => {
      setLoadingTeams(true);
      try {
        const response = await axiosInstance.get('/teams');
        setTeams(response.data);
        setErrorTeams(null);
      } catch (error) {
        console.error('Ошибка при загрузке команд:', error);
        setErrorTeams('Не удалось загрузить команды.');
      } finally {
        setLoadingTeams(false);
      }
    };

    fetchRoles();
    fetchTeams();
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    if (!formData.username || !formData.email || !formData.password || !formData.role_id) {
      setErrorSubmit('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    setSubmitting(true);
    setErrorSubmit(null);
    setSuccessSubmit(false);

    try {
      const payload = {
        username: formData.username, // Изменено на username для корректной передачи на сервер
        email: formData.email,
        password: formData.password,
        role_id: parseInt(formData.role_id),
        team_id: formData.team_id ? parseInt(formData.team_id) : null,
        office_id: formData.office_id,
      };

      const response = await axiosInstance.post('/users', payload);
      console.log('Пользователь создан:', response.data);

      setSuccessSubmit(true);
      setFormData({
        username: '',
        email: '',
        password: '',
        role_id: '',
        team_id: '',
        office_id: auth.user?.office_id || null,
      });

      if (onUserCreated) {
        onUserCreated(response.data.user);
      }
    } catch (error) {
      console.error('Ошибка при добавлении пользователя:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorSubmit(error.response.data.message);
      } else {
        setErrorSubmit('Не удалось добавить пользователя. Попробуйте позже.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setErrorSubmit(null);
    setSuccessSubmit(false);
    setFormData({
      username: '', // Исправлено на username
      email: '',
      password: '',
      role_id: '',
      team_id: '',
      office_id: auth.user?.office_id || null,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Создать пользователя</DialogTitle>
      <DialogContent>
        {errorSubmit && <Alert severity="error" sx={{ mb: 2 }}>{errorSubmit}</Alert>}
        {successSubmit && <Alert severity="success" sx={{ mb: 2 }}>Пользователь успешно создан!</Alert>}
        <TextField
          autoFocus
          margin="dense"
          label="Логин"
          name="username" // Изменено на username для соответствия серверу
          type="text"
          fullWidth
          variant="outlined"
          value={formData.username}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="Email"
          name="email"
          type="email"
          fullWidth
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="Пароль"
          name="password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <FormControl fullWidth margin="dense" variant="outlined" required>
          <InputLabel id="role-label">Роль</InputLabel>
          {loadingRoles ? (
            <Box display="flex" alignItems="center" padding={2}>
              <CircularProgress size={24} />
              <Typography variant="body2" sx={{ ml: 2 }}>Загрузка ролей...</Typography>
            </Box>
          ) : errorRoles ? (
            <Typography color="error" variant="body2">{errorRoles}</Typography>
          ) : (
            <Select
              labelId="role-label"
              name="role_id"
              value={formData.role_id}
              onChange={handleChange}
              label="Роль"
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>

        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel id="team-label">Команда</InputLabel>
          {loadingTeams ? (
            <Box display="flex" alignItems="center" padding={2}>
              <CircularProgress size={24} />
              <Typography variant="body2" sx={{ ml: 2 }}>Загрузка команд...</Typography>
            </Box>
          ) : errorTeams ? (
            <Typography color="error" variant="body2">{errorTeams}</Typography>
          ) : (
            <Select
              labelId="team-label"
              name="team_id"
              value={formData.team_id}
              onChange={handleChange}
              label="Команда"
            >
              <MenuItem value="">
                <em>Не выбрано</em>
              </MenuItem>
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={submitting}>Отмена</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          disabled={submitting || loadingRoles || loadingTeams || errorRoles || errorTeams}
        >
          {submitting ? <CircularProgress size={24} /> : 'Создать'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUser;
