// src/pages/Chats/ChatWindow.js
import React, { useEffect, useState, useRef } from 'react';
import axios from '../../utils/axiosInstance';
import MessageInput from './MessageInput';
import MessageItem from './MessageItem';
import './ChatWindow.css';
import { useAuth } from '../../context/AuthContext';
import { io } from 'socket.io-client';

const ChatWindow = ({ chat }) => {
  const { auth } = useAuth();
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [errorMessages, setErrorMessages] = useState(null);
  const messagesEndRef = useRef(null);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!auth.token) {
        setErrorMessages('Вы не авторизованы. Пожалуйста, войдите.');
        setLoadingMessages(false);
        return;
      }
      try {
        // Ожидаем, что эндпоинт /chats/:id/messages вернёт { messages: [...] }
        const response = await axios.get(`/chats/${chat.id}/messages`);
        if (response.data && Array.isArray(response.data.messages)) {
          setMessages(response.data.messages);
        } else {
          setMessages([]);
        }
      } catch (error) {
        console.error('Ошибка при загрузке сообщений:', error);
        setErrorMessages('Не удалось загрузить сообщения.');
      } finally {
        setLoadingMessages(false);
      }
    };

    fetchMessages();
  }, [chat.id, auth.token]);

  useEffect(() => {
    if (!auth.token) return;
    const newSocket = io(process.env.REACT_APP_API_BASE_URL || 'http://65.108.233.13:5000', {
      auth: {
        token: auth.token,
      },
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Подключен к Socket.io');
      newSocket.emit('joinChat', chat.id);
    });

    newSocket.on('disconnect', () => {
      console.log('Отключен от Socket.io');
    });

    newSocket.on('newMessage', (message) => {
      if (message.chat_id === chat.id) {
        setMessages((prev) => [...prev, message]);
        scrollToBottom();
      }
    });

    newSocket.on('error', (err) => {
      console.error('Socket.io ошибка:', err);
    });

    return () => {
      if (newSocket) {
        newSocket.emit('leaveChat', chat.id);
        newSocket.disconnect();
      }
    };
  }, [auth.token, chat.id]);

  const handleSendMessage = (content) => {
    if (socket && socket.connected) {
      socket.emit('sendMessage', { chatId: chat.id, content });
    } else {
      alert('Нет соединения с чатом, попробуйте обновить страницу.');
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (loadingMessages) {
    return <div>Загрузка сообщений...</div>;
  }

  if (errorMessages) {
    return <div className="error">{errorMessages}</div>;
  }

  return (
    <div className="chat-window">
      <div className="chat-header">
        <h2>{chat.name || 'Чат'}</h2>
      </div>
      <div className="messages-container">
        {messages.map((message) => (
          <MessageItem key={message.id} message={message} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <MessageInput onSend={handleSendMessage} />
    </div>
  );
};

export default ChatWindow;
