// src/pages/Users/UserDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  CircularProgress, 
  Alert, 
  Button, 
  Avatar, 
  IconButton, 
  Tooltip 
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { motion } from 'framer-motion';
import EditUserModal from './EditUserModal'; // Новый компонент для редактирования пользователя

const UserDetail = () => {
  const { id } = useParams(); // Получаем ID пользователя из маршрута
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Состояние для модального окна редактирования

  // Функция для загрузки данных пользователя
  const fetchUser = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/users/${id}`);
      if (response.data) {
        setUser(response.data); // Данные находятся прямо в response.data
        setError(null);
      } else {
        setError('Данные пользователя не найдены.');
      }
    } catch (err) {
      console.error('Ошибка при загрузке пользователя:', err);
      if (err.response && err.response.status === 404) {
        setError('Пользователь не найден.');
      } else {
        setError('Ошибка при загрузке данных пользователя. Попробуйте позже.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      setError('ID пользователя не найден в маршруте.');
      setLoading(false);
      return;
    }
    fetchUser();
  }, [id]);

  // Функция для обновления данных пользователя после редактирования
  const handleUserUpdate = (updatedUser) => {
    setUser(updatedUser);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Alert severity="error">{error}</Alert>
        <Box mt={2}>
          <motion.div 
            whileHover={{ scale: 1.05 }} 
            whileTap={{ scale: 0.95 }} 
            transition={{ duration: 0.3 }}
          >
            <Button variant="contained" onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
              Назад
            </Button>
          </motion.div>
        </Box>
      </Box>
    );
  }

  return (
    <Box p={4}>
      {/* Кнопка "Назад" с анимацией */}
      <motion.div 
        whileHover={{ scale: 1.05 }} 
        whileTap={{ scale: 0.95 }} 
        transition={{ duration: 0.3 }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
          Назад
        </Button>
      </motion.div>

      {/* Карточка с деталями пользователя */}
      <Card sx={{ maxWidth: 600, margin: '20px auto', boxShadow: 3 }}>
        <CardContent>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Avatar
              sx={{ width: 100, height: 100, mb: 2 }}
              src={user.avatarUrl || '/default-avatar.png'} // URL аватара
              alt={`${user.username}`}
            />
            <Typography variant="h5" gutterBottom>
              {user.username}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              {user.email}
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="body1"><strong>ID:</strong> {user.id}</Typography>
            <Typography variant="body1"><strong>Роль:</strong> {user.userRole?.name || 'Не указана'}</Typography>
            <Typography variant="body1"><strong>Офис:</strong> {user.office?.name || 'Не указан'}</Typography>
            <Typography variant="body1"><strong>Команда:</strong> {user.team?.name || 'Не указана'}</Typography>
            <Typography variant="body1"><strong>Статус:</strong> {user.blocked ? 'Заблокирован' : 'Активен'}</Typography>
            <Typography variant="body1"><strong>Дата создания:</strong> {new Date(user.created_at).toLocaleDateString()}</Typography>
            <Typography variant="body1"><strong>Последнее обновление:</strong> {new Date(user.updated_at).toLocaleDateString()}</Typography>
          </Box>
        </CardContent>
        {/* Кнопка "Редактировать пользователя" */}
        <Box display="flex" justifyContent="flex-end" p={2}>
          <motion.div 
            whileHover={{ scale: 1.05, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} 
            whileTap={{ scale: 0.95 }} 
            transition={{ duration: 0.3 }}
          >
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<EditIcon />} 
              onClick={() => setIsEditModalOpen(true)}
            >
              Редактировать
            </Button>
          </motion.div>
        </Box>
      </Card>

      {/* Модальное окно для редактирования пользователя */}
      {isEditModalOpen && (
        <EditUserModal 
          open={isEditModalOpen} 
          onClose={() => setIsEditModalOpen(false)} 
          user={user} 
          onUserUpdated={handleUserUpdate} 
        />
      )}
    </Box>
  );
};

export default UserDetail;
