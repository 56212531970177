// src/pages/Logout/Logout.js

import React, { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Logout;
